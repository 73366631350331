import React from "react"

import { Stack, Text } from "@fluentui/react"
import ContentBox from "./ContentBox"

const mainStackStyles = {
    root: {
        marginBottom: 22,
    },
}
const mainStackItmeStyles = {
    root: {
        marginBottom: 40,
    },
}

export default function TitleContent(props) {
    return (
        <Stack styles={mainStackStyles}>
            <Stack.Item styles={mainStackItmeStyles} grow={2}>
                <Text variant="xxLarge">{props.title}</Text>
            </Stack.Item>
            <Stack.Item>{props.children}</Stack.Item>
        </Stack>
    )
}
