import {
    Button,
    CommandBar,
    DefaultButton,
    DialogType,
    Image,
    ImageFit,
    Stack,
    Text,
    useTheme,
} from "@fluentui/react"
import { Canvas } from "@react-three/fiber"
import { useState } from "react"
import config from "../config"
import { ObjStage } from "../controls/Image"
import { useEntityFromConfig } from "../hooks/Entity"
import Modal from "../layouts/Modal"
import { itemsPublicConfig } from "./ItemsPublic"
import { useRoomsConfig } from "./Rooms"
import { v4 as uuidv4 } from "uuid"
import { useGlobalState } from "../state"
import { cloneDeep } from "lodash"
import { materialsPublicConfig } from "./MaterialsPublic"
import RoomsPanel from "./RoomsPanel"
import PartsPanel from "./Parts"
import ConfirmDialog from "../layouts/ConfimDialog"
import { useProjectsConfig } from "./Projects"

function NewModal(props) {
    const room = props.room
    const useItems = useEntityFromConfig(itemsPublicConfig)
    const useRooms = useEntityFromConfig(useRoomsConfig())
    const items = useItems.filter([{ field: "isBase", type: "eq", value: 1 }])
    const theme = useTheme()
    const dialogStyles = {
        root: {
            selectors: {
                "@media (min-width: 480px)": {
                    maxWidth: "300px",
                },
            },
        },
    }

    const verticalGapStackTokens = {
        childrenGap: 15,
        padding: 15,
    }

    const rItems = []

    for (const i of items) {
        rItems.push(
            <div
                key={i.id}
                style={
                    {
                        //border: "1px " + theme.palette.themePrimary + " dashed",
                    }
                }
            >
                <Stack horizontal>
                    {i.imageId && (
                        <Image
                            imageFit={ImageFit.centerContain}
                            height="200px"
                            width="300px"
                            src={config.images.baseUrl + i.imageId}
                        />
                    )}
                    {i.imageId == null && (
                        <ObjStage
                            height="200px"
                            backgroud="black"
                            gltfPath={config.images.baseUrl + i.modelId}
                        ></ObjStage>
                    )}
                    <Stack.Item
                        styles={{ root: { padding: theme.spacing.s1 } }}
                        align="center"
                    >
                        <Stack tokens={verticalGapStackTokens}>
                            <Text variant="xLarge">{i.name}</Text>
                            <DefaultButton
                                onClick={() => {
                                    const room2 = { ...room }
                                    const bi = cloneDeep(room2.items)
                                    bi.push({
                                        id: uuidv4(),
                                        itemId: i.id,
                                        x: 0,
                                        y: 0,
                                        z: 0,
                                        subitems: [],
                                    })
                                    room2.items = bi
                                    useRooms.dispatch.upsert(room2)
                                    props.onClose()
                                }}
                            >
                                Add To Room
                            </DefaultButton>
                        </Stack>
                    </Stack.Item>
                </Stack>
            </div>
        )
    }
    return (
        <Modal
            styles={dialogStyles}
            title={"Add Item"}
            onClose={() => {
                props.onClose()
            }}
            maxWidth={300}
        >
            <Stack>{rItems}</Stack>
        </Modal>
    )
}

function useMaterialButtons(roomId) {
    const useMaterials = useEntityFromConfig(materialsPublicConfig)
    const useRoom = useEntityFromConfig(useRoomsConfig())

    const wood = useMaterials.filter([
        { field: "type", type: "eq", value: "wood" },
    ])
    const metal = useMaterials.filter([
        { field: "type", type: "eq", value: "metal" },
    ])
    const room = useRoom.filter([{ field: "id", type: "eq", value: roomId }])

    const _roomMetal = useMaterials.filter([
        { field: "id", type: "eq", value: room[0].metalMaterialId },
    ])
    const roomMetal = _roomMetal && _roomMetal[0] ? _roomMetal[0] : null
    const _roomWood = useMaterials.filter([
        { field: "id", type: "eq", value: room[0].woodMaterialId },
    ])
    const roomWood = _roomWood && _roomWood[0] ? _roomWood[0] : null

    return [
        {
            key: "metal",
            text: "Metal",
            iconProps: {
                iconName: "",
                ...(roomMetal
                    ? {
                          styles: {
                              root: {
                                  color: roomMetal.color,
                                  backgroundColor: roomMetal.color,
                                  height: "80%",
                                  width: "20px",
                                  "&:hover": {
                                      color: roomMetal.color,
                                  },
                              },
                          },
                      }
                    : {}),
            },
            ariaLabel: "Metal Material",

            subMenuProps: {
                items: metal.map((w) => {
                    return {
                        key: w.id,
                        text: w.name,
                        iconProps: {
                            styles: {
                                root: {
                                    backgroundColor: w.color,
                                    height: "100%",
                                },
                            },
                        },
                        onClick: () => {
                            useRoom.dispatch.upsert({
                                ...room[0],
                                metalMaterialId: w.id,
                            })
                        },
                    }
                }),
            },
        },
        {
            key: "wood",
            text: "Wood",
            iconProps: {
                imageProps: {
                    src: config.images.baseUrl + roomWood.imageId,
                },
                ...(roomMetal
                    ? {
                          styles: {
                              root: {
                                  height: "80%",
                                  width: "20px",
                              },
                          },
                      }
                    : {}),
            },
            ariaLabel: "Wood Material",

            subMenuProps: {
                items: wood.map((w) => {
                    return {
                        key: w.id,
                        text: w.name,
                        iconProps: {
                            imageProps: {
                                src: config.images.baseUrl + w.imageId,
                            },
                        },
                        onClick: () => {
                            useRoom.dispatch.upsert({
                                ...room[0],
                                woodMaterialId: w.id,
                            })
                        },
                    }
                }),
            },
        },
    ]
}

export default function PublicTopBar(props) {
    const [modal, setModal] = useState(null)
    const useRooms = useEntityFromConfig(useRoomsConfig())
    const useProjects = useEntityFromConfig(useProjectsConfig())
    const materialButtons = useMaterialButtons(props.room.id)
    const buttons = [
        {
            key: "rooms",
            text: "Rooms",
            iconProps: { iconName: "OpenPane" },
            split: true,
            ariaLabel: "Rooms",
            splitButtonAriaLabel: "Show Rooms",
            onClick: () => {
                setModal(
                    <RoomsPanel
                        currentRoom={props.room.id}
                        onClose={() => setModal(null)}
                        onSelected={props.onRoomSelected}
                    ></RoomsPanel>
                )
            },
        },
        {
            key: "newItem",
            text: "Add Frame",
            iconProps: { iconName: "Add" },
            split: true,
            ariaLabel: "New",
            splitButtonAriaLabel: "More new options",
            onClick: () => {
                setModal(
                    <NewModal
                        room={props.room}
                        onClose={() => setModal(null)}
                    ></NewModal>
                )
            },
        },
        {
            key: "zoomIn",
            text: "Zoom In",
            iconProps: { iconName: "ZoomIn" },
            ariaLabel: "Zoom In",
            disabled: props.zoom >= 3,
            onClick: () => {
                if (props.zoom < 3) {
                    props.setZoom(props.zoom + 0.2)
                }
            },
        },
        {
            key: "zoomOut",
            text: "Zoom Out",
            iconProps: { iconName: "ZoomOut" },
            ariaLabel: "Zoom Out",
            disabled: props.zoom <= 1,
            onClick: () => {
                if (props.zoom > 1) {
                    props.setZoom(props.zoom - 0.2)
                }
            },
        },
        ...materialButtons,
        {
            key: "partsList",
            text: "Parts List",
            iconProps: { iconName: "List" },
            ariaLabel: "Parts List",
            onClick: () => {
                setModal(
                    <PartsPanel
                        currentRoom={props.room.id}
                        onClose={() => setModal(null)}
                        onSelected={props.onRoomSelected}
                    ></PartsPanel>
                )
            },
        },
    ]

    const farItems = [
        {
            key: "light",
            text: "",
            iconProps: {
                iconName: props.lights ? "VideoLightOff" : "Flashlight",
            },
            split: true,
            ariaLabel: "Light off",
            splitButtonAriaLabel: "Light Off",
            onClick: () => {
                props.setLights(!props.lights)
            },
        },
        {
            key: "clear",
            text: "",
            iconProps: {
                iconName: "Clear",
            },
            split: true,
            ariaLabel: "Clear",
            splitButtonAriaLabel: "Clear",
            onClick: () => {
                setModal(
                    <ConfirmDialog
                        onDismiss={() => {
                            setModal(null)
                        }}
                        dialogContentProps={{
                            type: DialogType.normal,
                            title: "Reset All Rooms?",
                            closeButtonAriaLabel: "Close",
                            subText:
                                "This will clear all rooms and all content. All work will be lost. Are you sure you want to do this?",
                        }}
                        actionText="Clear"
                        dismissText="Do Nothing"
                        onConfirm={() => {
                            useRooms.dispatch.initialize([])
                            useProjects.dispatch.initialize([])
                        }}
                    >
                        Bla bla bla
                    </ConfirmDialog>
                )
            },
        },
    ]
    return (
        <>
            {modal}
            <CommandBar items={buttons} farItems={farItems} />
        </>
    )
}
