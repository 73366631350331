import { FocusTrapZone, Spinner, SpinnerSize } from "@fluentui/react"

export default function WaitSpinner(props) {
    return (
        <FocusTrapZone
            style={{
                zIndex: "100000",
                opacity: "0.3",
                position: "absolute",
                top: 0,
                left: 0,
                width: "100vw",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "black",
            }}
        >
            <Spinner
                styles={{ root: { opacity: "1" } }}
                size={SpinnerSize.large}
                label="Please wait, PDF being generated"
            ></Spinner>
        </FocusTrapZone>
    )
}
