import { Html, useCursor, useGLTF } from "@react-three/drei"
import { useEffect, useRef, useState } from "react"
import config from "../config"

import { FontIcon } from "@fluentui/react/lib/Icon"
import { mergeStyles } from "@fluentui/react/lib/Styling"
import { Stack, useTheme } from "@fluentui/react"
import { useFrame, useThree } from "@react-three/fiber"
import { constant, map } from "lodash"
import * as THREE from "three"

export function useMaterialMap(material) {
    const loader = new THREE.TextureLoader()
    const [map, setMap] = useState()

    useEffect(() => {
        if (material && material.imageId) {
            loader.load(
                config.images.baseUrl + material.imageId,
                function (texture) {
                    texture.encoding = THREE.sRGBEncoding
                    setMap(texture)
                },

                undefined,
                function (err) {
                    console.error("Could not load texture " + material.id)
                }
            )
        }
    }, [material])
    return map
}
export function SubModels(props) {
    const nodes = Object.values(props.nodes)
    const woodMap = useMaterialMap(props.woodMaterial)

    const woodMaterial = new THREE.MeshPhongMaterial({
        map: woodMap,
        transparent: false,
    })
    const metalMaterial = new THREE.MeshPhongMaterial({
        color: props.metalMaterial ? props.metalMaterial.color : null,
        transparent: true,
    })
    const light = new THREE.MeshStandardMaterial({
        color: "white",
        emissive: "white",
        emissiveIntensity: 3000,
    })

    const glass = new THREE.MeshPhysicalMaterial({
        roughness: 0.05,
        transmission: 0.98,
        thickness: 0.5,
    })
    const m = []
    for (const i of nodes) {
        if (i.name === "MAIN") {
            continue
        }
        if (i.material) {
            m.push(
                <mesh
                    geometry={i.geometry}
                    material={
                        i.material.name === "WOOD"
                            ? woodMaterial
                            : i.material.name === "METAL"
                            ? metalMaterial
                            : i.material.name === "LIGHT"
                            ? light
                            : i.material.name === "GLASS"
                            ? glass
                            : i.material
                    }
                    {...props}
                />
            )
        }
    }
    return <>{m}</>
}

function Model(props) {
    const { nodes, materials } = useGLTF(
        config.images.baseUrl + props.modelId,
        true
    )

    const map = useMaterialMap(props.material)
    const ref = useRef()

    const position = props.position ? props.position : [0, 0, 0]

    const [hovered, setHovered] = useState()
    useCursor(hovered)
    const material = new THREE.MeshPhongMaterial({
        color: props.material ? props.material.color : "black",
        map: map,
        transparent: false,
        repeat: [10, 10],
    })

    const sharedProps = {
        receiveShadow: false,
        position: position,
        castShadow: true,
        onPointerOver: () => setHovered(true),
        onPointerOut: () => setHovered(false),
        onClick: (e) => {
            props.onClick({
                geometry: nodes.MAIN.geometry,
                position,
                mesh: e,
                groupRef: ref,
            })
        },
    }
    return (
        <group ref={ref}>
            {nodes && (
                <SubModels
                    {...sharedProps}
                    nodes={nodes}
                    woodMaterial={props.woodMaterial}
                    metalMaterial={props.metalMaterial}
                />
            )}
            <mesh
                ref={props.meshRef}
                {...sharedProps}
                material={material}
                geometry={nodes.MAIN.geometry}
            />
        </group>
    )
}
export default function ItemRender(props) {
    const items = props.allItems
    let mousePos = useRef(null)
    const [dragging, setDragging] = useState(false)
    const [position, _setPosition] = useState([props.item.x, props.item.z, 0])
    const touchPosition = useRef(null)

    const modelRef = useRef()
    function setPosition(pos) {
        _setPosition(pos)
        props.item.x = pos[0]
        if (props.setPosition) {
            props.setPosition(pos)
        }
    }

    const theme = useTheme()

    useEffect(() => {
        const f = (event) => {
            setDragging(false)
        }
        window.addEventListener("mouseup", f)
        return () => {
            window.removeEventListener("mouseup", f)
        }
    })
    const item = items.find((i) => {
        return i.id === props.id
    })

    useFrame((state, delta, xrFrame) => {
        mousePos.current = state.mouse
        const vector = new THREE.Vector3()
        mousePos.current = new THREE.Vector3()
        if (touchPosition.current) {
            vector.set(touchPosition.current.x, touchPosition.current.y, 1)
        } else {
            vector.set(state.mouse.x, state.mouse.y, 1)
        }
        vector.unproject(state.camera) // -1,1 => -screen width/2,screen width/2
        vector.sub(state.camera.position).normalize()
        var distance = -state.camera.position.z / vector.z
        mousePos.current
            .copy(state.camera.position)
            .add(vector.multiplyScalar(distance))
        if (dragging) {
            let normalSet = true

            if (
                mousePos.current.getComponent(0) >=
                    props.positionLimit[0][0] + item.width / 2 &&
                mousePos.current.getComponent(0) <=
                    props.positionLimit[0][1] - item.width / 2
            ) {
                if (normalSet) {
                    if (props.viewPosition === "front") {
                        setPosition([
                            mousePos.current.getComponent(0),
                            0,
                            position[2],
                        ])
                    }
                    if (props.viewPosition === "right") {
                        setPosition([
                            position[0],
                            0,
                            -mousePos.current.getComponent(0) + 35,
                        ])
                    }
                }
            }
        }
    })
    const onDragStart = (e) => {
        setDragging(true)

        return false
    }
    const onDragEnd = (e) => {
        setDragging(false)
        for (const si of props.items) {
            if (si.id === props.item.id) {
                continue
            }

            const sModel = items.find((i) => i.id === si.itemId)

            if (
                si.x + sModel.width / 2 > props.item.x - item.width / 2 - 12 &&
                si.x + sModel.width / 2 < props.item.x - item.width / 2 + 30
            ) {
                setPosition([
                    si.x + sModel.width / 2 + item.width / 2,
                    props.item.z,
                    0,
                ])

                break
            }

            if (
                props.item.x + item.width / 2 + 12 > si.x - sModel.width / 2 &&
                props.item.x + item.width / 2 - 30 < si.x - sModel.width / 2
            ) {
                setPosition([
                    si.x - sModel.width / 2 - item.width / 2,
                    props.item.z,
                    0,
                ])
                break
            }
        }
    }

    const iconClass = mergeStyles({
        fontSize: 20,
        height: 20,
        width: 20,
        margin: "2px 2px",
    })

    const iconHousing = mergeStyles({
        backgroundColor: theme.palette.themeLight,
        borderRadius: "50%",
        height: 23,
        width: 23,
        cursor: "move",
        border: "1px yellow solid",
        userSelect: "none",
    })
    const iconCheck = mergeStyles({
        cursor: "pointer",
    })
    if (items && items[0]) {
        const i = items.find((i) => {
            return i.id === props.id
        })
        let opaque = false

        for (const si of props.items) {
            if (si.id === props.item.id) {
                continue
            }
            const sModel = items.find((i) => i.id === si.itemId)

            if (
                si.x + sModel.width > props.item.x - i.width &&
                si.x - sModel.width < props.item.x + i.width
            ) {
                //opaque = true
            }
        }

        if (i) {
            return (
                <>
                    <Model
                        meshRef={modelRef}
                        opaque={opaque}
                        {...props}
                        position={position}
                        modelId={i.modelId}
                        material={props.material}
                        polygonOffset={props.polygonOffset}
                        woodMaterial={props.woodMaterial}
                        metalMaterial={props.metalMaterial}
                    />
                    {props.base && (
                        <Html
                            position={[
                                position[0] - 3,
                                position[1] + 95,
                                position[2],
                            ]}
                        >
                            <Stack horizontal>
                                <div
                                    className={iconHousing}
                                    onPointerDown={(e) => {
                                        onDragStart(e)
                                    }}
                                    onPointerUp={(e) => {
                                        onDragEnd(e)
                                    }}
                                    onTouchMove={(event) => {
                                        touchPosition.current = {
                                            x:
                                                (event.changedTouches[0]
                                                    .clientX /
                                                    window.innerWidth) *
                                                    2 -
                                                1,
                                            y:
                                                (event.changedTouches[0]
                                                    .clientY /
                                                    window.innerHeight) *
                                                    2 +
                                                1,
                                        }
                                    }}
                                    onDragStart={() => {
                                        return false
                                    }}
                                    // draggable="true"
                                >
                                    <FontIcon
                                        iconName="Move"
                                        className={iconClass}
                                    />
                                </div>
                                <div
                                    className={iconHousing + " " + iconCheck}
                                    onClick={(e) => {
                                        if (props.disableClick) {
                                            return
                                        }
                                        props.onClick({
                                            groupRef: modelRef,
                                        })
                                    }}
                                >
                                    <FontIcon
                                        iconName="CheckMark"
                                        className={iconClass}
                                    />
                                </div>
                            </Stack>
                        </Html>
                    )}
                </>
            )
        } else {
            return null
        }
    } else {
        return null
    }
}
