import React from "react"
import {
    Callout,
    DefaultButton,
    DelayedRender,
    Icon,
    Text,
    TextField,
    useTheme,
} from "@fluentui/react"
import { useId } from "@fluentui/react-hooks"
import { useState } from "react"
import QRCode from "react-qr-code"
import { saveAs } from "file-saver"
import * as ReactDOMServer from "react-dom/server"

export default function InfoField(props) {
    const id = useId("info-" + props.key)
    const labelId = useId("callout-label")
    const [showCallout, setShowCallout] = useState(false)
    const theme = useTheme()
    function MyIcon() {
        return (
            <Icon
                id={id}
                styles={{ root: { cursor: "pointer" } }}
                onClick={() => {
                    if (navigator.clipboard) {
                        navigator.clipboard
                            .writeText(
                                props.valueFunction
                                    ? props.valueFunction(props.values)
                                    : props.value
                            )
                            .then(() => {
                                setShowCallout(true)
                            })
                    }
                }}
                iconName="Copy"
            />
        )
    }
    function URLIcon() {
        return (
            <Icon
                id={id}
                styles={{ root: { cursor: "pointer" } }}
                onClick={() => {
                    window.open(props.value)
                }}
                iconName="OpenInNewWindow"
            />
        )
    }

    function MyCallout() {
        return (
            <>
                {showCallout && (
                    <Callout
                        styles={{
                            root: {
                                width: 320,
                                padding: theme.spacing.l1,
                            },
                        }}
                        ariaLabelledBy={labelId}
                        target={`#${id}`}
                        setInitialFocus
                        onDismiss={() => {
                            setShowCallout(false)
                        }}
                    >
                        <DelayedRender>
                            <Text id={labelId}>Data copied to clipboard</Text>
                        </DelayedRender>
                    </Callout>
                )}
            </>
        )
    }

    return (
        <>
            <MyCallout />
            {props.component && <>{props.valueFunction(props.values)}</>}
            {!props.component && (
                <TextField
                    label={props.label}
                    name={props.name}
                    value={
                        props.valueFunction
                            ? props.valueFunction(props.values)
                            : props.value
                    }
                    readOnly={props.displayOnly ? true : false}
                    onChange={props.displayOnly ? null : props.onChange}
                    onRenderSuffix={
                        props.copy
                            ? () => {
                                  return <MyIcon />
                              }
                            : props.clickableUrl
                            ? () => {
                                  return <URLIcon />
                              }
                            : null
                    }
                    styles={
                        props.displayOnly
                            ? { fieldGroup: { border: "none" } }
                            : {}
                    }
                />
            )}
            {props.qrCode && (
                <>
                    <div
                        style={{
                            background: theme.palette.white,
                            padding: "16px",
                        }}
                    >
                        <QRCode
                            bgColor={theme.palette.black}
                            fgColor={theme.palette.white}
                            value={
                                props.valueFunction
                                    ? props.valueFunction(props.values)
                                    : props.value
                            }
                        />
                    </div>
                    <DefaultButton
                        onClick={() => {
                            var blob = new Blob(
                                [
                                    ReactDOMServer.renderToStaticMarkup(
                                        <QRCode
                                            bgColor={theme.palette.black}
                                            fgColor={theme.palette.white}
                                            value={
                                                props.valueFunction
                                                    ? props.valueFunction(
                                                          props.values
                                                      )
                                                    : props.value
                                            }
                                        />
                                    ),
                                ],
                                { type: "image/svg+xml" }
                            )
                            saveAs(blob, "QRCODE.svg")
                        }}
                    >
                        Download QR Code
                    </DefaultButton>
                </>
            )}
        </>
    )
}
