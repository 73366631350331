import React, { useRef, useState, useCallback } from "react"

import { Stack, useTheme, Text, ContextualMenu } from "@fluentui/react"

import { Icon } from "@fluentui/react/lib/Icon"
import { useAuth0 } from "@auth0/auth0-react"
import { useEntityFromConfig } from "../hooks/Entity"
import config from "../config"
import { userConfig, UserForm } from "./Users"
import { infoConfig } from "./Infos"
import UpsertPanel from "../layouts/UpsertPanel"
import { Persona, PersonaSize } from "@fluentui/react/lib/Persona"

function useValues(user) {
    const theme = useTheme()

    const stackStyles = {
        root: {
            background: theme.palette.themeDark,
            color: theme.palette.white,
            height: 50,
            alignContent: "center",
            lineHeight: 50,
            alignItems: "center",
        },
    }
    const fontStyles = {
        root: {
            color: theme.palette.white,
            paddingLeft: 1,
        },
    }

    const logoStackStyle = {
        root: {
            paddingLeft: 15,
            width: 21,
        },
    }

    const rightStackItmesStyle = {
        root: {
            paddingRight: theme.spacing.s1,
            paddingLeft: theme.spacing.s1,
        },
    }

    let initials = ".."
    if (user) {
        const name = user.contact.name
        let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu")

        initials = [...name.matchAll(rgx)] || []

        initials = (
            (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
        ).toUpperCase()
    }

    const currentPersona = {
        imageUrl: null,
        imageInitials: initials,
        lineHeight: 40,
        initialsColor: theme.palette.neutralTertiary,
    }

    const currentPersonaStyles = {
        root: { width: 21, cursor: "pointer" },
    }

    return {
        stackStyles,
        fontStyles,
        logoStackStyle,
        rightStackItmesStyle,
        currentPersona,
        currentPersonaStyles,
    }
}

function UserMenu(props) {
    const { logout } = useAuth0()
    const linkRef = useRef(null)
    const [modal, setModal] = useState(null)
    const useUsers = useEntityFromConfig(userConfig)
    const infos = useEntityFromConfig(infoConfig)

    const info = infos.filter([])

    let users = null

    if (info[0]) {
        users = useUsers.filter([
            { type: "one", field: "id", value: info[0].userId },
        ])
    }

    const { currentPersona, currentPersonaStyles } = useValues(
        users ? users[0] : null
    )

    const [showContextualMenu, setShowContextualMenu] = useState(false)
    const onShowContextualMenu = useCallback((ev) => {
        ev.preventDefault() // don't navigate
        setShowContextualMenu(true)
    }, [])
    const onHideContextualMenu = useCallback(
        () => setShowContextualMenu(false),
        []
    )

    const submitRef = useRef(() => {
        setModal(null)
    })
    const user = users ? users[0] : false

    const menuItems = user
        ? [
              {
                  key: "edit",
                  text: "Edit Profile",
                  iconProps: {
                      iconName: "EditContact",
                  },
                  onClick: () => {
                      if (info) {
                          setModal(
                              <UpsertPanel
                                  buttonLabel="Your Profile"
                                  AddForm={UserForm}
                                  onClose={() => {
                                      setModal(null)
                                  }}
                                  submitRef={submitRef}
                                  values={user}
                              />
                          )
                      }
                  },
              },

              {
                  key: "logout",
                  text: "Logout",
                  onClick: () => logout({ returnTo: config.auth0.redirectUri }),
                  iconProps: {
                      iconName: "SignOut",
                  },
              },
          ]
        : [
              {
                  key: "logout",
                  text: "Logout",
                  onClick: () => logout({ returnTo: config.auth0.redirectUri }),
                  iconProps: {
                      iconName: "SignOut",
                  },
              },
          ]
    return (
        <>
            {modal && <div>{modal}</div>}
            <Persona
                ref={linkRef}
                onClick={onShowContextualMenu}
                styles={currentPersonaStyles}
                {...currentPersona}
                size={PersonaSize.size32}
            />
            <ContextualMenu
                items={menuItems}
                hidden={!showContextualMenu}
                target={linkRef}
                onItemClick={onHideContextualMenu}
                onDismiss={onHideContextualMenu}
            />
        </>
    )
}

export default function Header(props) {
    const { stackStyles, logoStackStyle, rightStackItmesStyle } = useValues()
    const theme = useTheme()

    const textStyles = {
        root: {
            color: theme.palette.white,
            fontSize: theme.fonts.large.fontSize,
        },
    }

    return (
        <div className="no-print">
            <Stack horizontal styles={stackStyles}>
                <Stack.Item grow={1}>
                    <Icon styles={logoStackStyle} iconName="CubeShape" />
                    <Text styles={textStyles}>
                        Seven Point Configurator Admin
                    </Text>
                </Stack.Item>
                <Stack.Item styles={rightStackItmesStyle}>
                    <UserMenu />
                </Stack.Item>
            </Stack>
        </div>
    )
}
