import {
    CommandBar,
    DetailsList,
    DetailsListLayoutMode,
    SelectionMode,
    Text,
} from "@fluentui/react"
import { uniqueId } from "lodash"
import React, { useEffect, useLayoutEffect, useRef, useState } from "react"
import { useEntityFromConfig } from "../hooks/Entity"
import Modal from "../layouts/Modal"
import Panel from "../layouts/Panel"
import { itemsPublicConfig } from "./ItemsPublic"
import { materialsPublicConfig } from "./MaterialsPublic"
import { useRoomsConfig } from "./Rooms"
import { PublicSaveForm, SaveForm } from "./Saves"
import { skusConfig } from "./Skus"
import { skusConfigPublic } from "./SkusPublic"
import axios from "axios"
import config from "../config"
import fileDownload from "js-file-download"
import WaitSpinner from "../layouts/WaitSpinner"
import DesignStage, { DesignStageCanvas } from "./DesignStage"
import { usePublicProjectsConfig } from "./Projects"
import { project } from "@sevenpoint/schema"

function flattenItemsFromRoom(room) {
    const items = []

    for (const i of room.items) {
        if (i.subitems) {
            for (const i2 of i.subitems) {
                items.push(i2)
            }
        }
        items.push(i)
    }
    return items
}

function formatSkuRows(sr, woodMaterial, metalMaterial, html = true) {
    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    })
    let grandTotal = 0

    for (const r of sr) {
        grandTotal = grandTotal + r.total
        r.price = formatter.format(r.price / 100)
        r.total = formatter.format(r.total / 100)
    }

    sr.push({
        total: html ? <b>{formatter.format(grandTotal / 100)}</b> : "",
        gtotal: formatter.format(grandTotal / 100),
    })

    return sr
}

function applyFinishCodes(sr, woodMaterial, metalMaterial) {
    const offset = 18

    const woodRequired = [2, 3, 6, 7]
    const metalRequired = [4, 5, 6, 7]

    for (const r of sr) {
        const indicator = parseInt(r.sku[offset])
        if (woodRequired.includes(indicator)) {
            r.sku =
                r.sku.substring(0, 12) +
                woodMaterial.serialBase +
                r.sku.substring(15)
        }
        if (metalRequired.includes(indicator)) {
            /// 9,10,11
            r.sku =
                r.sku.substring(0, 9) +
                metalMaterial.serialBase +
                r.sku.substring(12)
        }
    }
    return sr
}

function skuRows(
    skus,
    items,
    catalogue,
    woodMaterial,
    metalMaterial,
    html = true
) {
    const sr = []

    for (const i of items) {
        const ci = catalogue.find((c) => c.id === i.itemId)
        if (ci === undefined) {
            continue
        }
        const ss = ci.serialBase.split(",")
        for (const s of ss) {
            //const ps = applyFinishCodes(sr, woodMaterial, metalMaterial)
            const ps = s
            //console.log(s)
            const si = skus.find((e) => e.serialBase === ps)
            if (si === undefined) {
                continue
            }
            const og = sr.find((e) => e.sku === s)
            if (og) {
                og.quanity++
                og.total = si.price * og.quanity
            } else {
                sr.push({
                    key: uniqueId(),
                    sku: s,
                    name: si.name,
                    material: woodMaterial.name + "/" + metalMaterial.name,
                    quanity: 1,
                    price: si.price,
                    total: si.price,
                })
            }
        }
    }

    return formatSkuRows(sr, woodMaterial, metalMaterial, html)
}

function RoomPartList(props) {
    const room = props.room
    const skus = props.skus
    const catalogue = props.catalogue

    const columns = [
        {
            key: "sku",
            name: "Sku",
            fieldName: "sku",
            isResizable: true,
            minWidth: 200,
            maxWidth: 200,
        },
        {
            key: "name",
            name: "Name",
            fieldName: "name",
            isResizable: true,
            minWidth: 400,
            maxWidth: 400,
        },
        {
            key: "material",
            name: "Material",
            fieldName: "material",
            isResizable: true,
        },
        {
            key: "quanity",
            name: "Quanity",
            fieldName: "quanity",
        },
    ]
    const items = flattenItemsFromRoom(room)
    const rows = skuRows(
        skus,
        items,
        catalogue,
        props.woodMaterial,
        props.metalMaterial
    )
    return (
        <div>
            <Text variant={"large"}>{room.name}</Text>
            <DetailsList
                items={rows}
                compact={false}
                columns={columns}
                selectionMode={SelectionMode.none}
                isHeaderVisible={true}
                layoutMode={DetailsListLayoutMode.justified}
            />
        </div>
    )
}

export function PartsList(props) {
    const rooms = props.rooms
    const catalogue = props.catalogue
    const materials = props.materials

    const things = []

    for (const room of rooms) {
        const woodMaterial = materials.find((e) => e.id === room.woodMaterialId)
        const metalMaterial = materials.find(
            (e) => e.id === room.metalMaterialId
        )

        things.push(
            <RoomPartList
                woodMaterial={woodMaterial}
                metalMaterial={metalMaterial}
                room={room}
                skus={props.skus}
                catalogue={catalogue}
            />
        )
    }
    return <div>{things}</div>
}

function ThankYouModal(props) {
    return (
        <Modal {...props}>
            <div style={{ textAlign: "center" }}>
                <h1>Thank you</h1>
                <p>
                    We have saved your configuration and will be reaching out to
                    you to finalize your order.
                </p>
                <p>
                    You may continue to use the Modulator but future changes
                    will not automaticly be saved. <br />
                    You must come back and use the save and inquire function to
                    update your order
                </p>
            </div>
        </Modal>
    )
}

function useGenerateImageForRoom() {
    const [room, setRoom] = useState(null)
    const canvasRef = useRef()

    const rfunc = async (room) => {
        setRoom(room)
        await new Promise((r) => setTimeout(r, 3000))

        const image = canvasRef.current.toDataURL("image/png")
        setRoom(null)
        return image
    }

    const cfunc = (props) => {
        return room ? (
            <div
                style={{
                    width: "1000px",
                    height: "1000px",
                    visibility: "hidden",
                    position: "absolute",
                }}
            >
                <DesignStageCanvas
                    setModal={() => {}}
                    room={room}
                    canvasRef={canvasRef}
                    preserveDrawingBuffer={true}
                    width={room.width}
                />
            </div>
        ) : null
    }
    return [rfunc, cfunc]
}

function PartsCommandBar(props) {
    const [modal, setModal] = useState(null)
    const [blob, setBlob] = useState(null)
    const [generateImageForRoom, Generator] = useGenerateImageForRoom()

    const skus = props.skus
    const rooms = props.rooms
    const catalogue = props.catalogue
    const materials = props.materials
    const project = props.project

    function SaveModel(props) {
        return (
            <Modal
                onClose={() => {
                    setModal(null)
                }}
                title={props.title}
            >
                <PublicSaveForm
                    showButtons={true}
                    onCancel={() => {
                        setModal(null)
                    }}
                    project={project}
                    onSubmit={async () => {
                        if (props.action) {
                            await props.action()
                        }
                        setModal(
                            <ThankYouModal
                                onClose={() => {
                                    setModal(null)
                                }}
                            />
                        )
                    }}
                />
            </Modal>
        )
    }

    useLayoutEffect(() => {
        if (blob !== null) {
            fileDownload(blob, "Order.pdf")
            setBlob(null)
        }
    }, [blob])

    const items = [
        {
            key: "save",
            text: "Save and Inquire",
            iconProps: { iconName: "Save" },
            split: true,
            ariaLabel: "Save and Inquire",
            splitButtonAriaLabel: "More New options",
            onClick: () => {
                setModal(<SaveModel />)
            },
        },
        {
            key: "print",
            text: "Print",
            iconProps: { iconName: "Print" },
            split: true,
            ariaLabel: "Print",
            onClick: async (value) => {
                setModal(
                    <SaveModel
                        title={"Fill out form to print copy"}
                        action={async () => {
                            const dataRooms = []
                            setModal(<WaitSpinner />)

                            for (const room of rooms) {
                                const woodMaterial = materials.find(
                                    (e) => e.id === room.woodMaterialId
                                )
                                const metalMaterial = materials.find(
                                    (e) => e.id === room.metalMaterialId
                                )
                                const items = flattenItemsFromRoom(room)
                                const rows = skuRows(
                                    skus,
                                    items,
                                    catalogue,
                                    woodMaterial,
                                    metalMaterial,
                                    false
                                )
                                dataRooms.push({
                                    name: room.name,
                                    rows: rows,
                                    image: await generateImageForRoom(room),
                                })
                            }
                            const obj = {
                                project: project,
                                rooms: dataRooms,
                            }

                            const response = await axios.post(
                                config.reporting.url + "/order",
                                obj,
                                {
                                    responseType: "blob",
                                    headers: {
                                        "content-type": "application/json",
                                    },
                                }
                            )
                            setModal(null)

                            fileDownload(response.data, "Order.pdf")

                            setBlob(response.data)
                        }}
                    />
                )
            },
        },
    ]

    return (
        <div>
            <Generator />
            {modal}
            <CommandBar items={items} />
        </div>
    )
}

export default function PartsPanel(props) {
    const useRooms = useEntityFromConfig(useRoomsConfig())
    const useSkus = useEntityFromConfig(skusConfigPublic)
    const useItems = useEntityFromConfig(itemsPublicConfig)
    const useMaterials = useEntityFromConfig(materialsPublicConfig)
    const useProjects = useEntityFromConfig(usePublicProjectsConfig())

    const skus = useSkus.filter([])
    const items = useItems.filter([])
    const rooms = useRooms.filter([])
    const materials = useMaterials.filter([])

    const projects = useProjects.filter([])

    const room = rooms[0] ? rooms : null
    const sku = skus[0] ? skus : null
    const item = items[0] ? items : null
    const material = materials[0] ? materials : null

    return (
        <Panel
            onClose={props.onClose}
            onSave={props.onSave}
            lightDismiss={false}
            isOpen={true}
            hideOverlay={false}
            title={"Parts and Skus"}
            largeNear
            customWidth={1200}
            hideButtons
        >
            <h1>{projects[0]?.name}</h1>

            {sku && room && item && material && (
                <div>
                    <PartsCommandBar
                        skus={sku}
                        rooms={room}
                        catalogue={item}
                        materials={material}
                        project={projects[0]}
                    />
                    <PartsList
                        skus={sku}
                        rooms={room}
                        catalogue={item}
                        materials={material}
                    />
                </div>
            )}
        </Panel>
    )
}
