import { Text, ThemeProvider, useTheme } from "@fluentui/react"
import React from "react"
import themes from "../themes"

function Headers(props) {
    const columns = props.columns
    const cols = []
    for (const c of columns) {
        cols.push(
            <th
                style={{
                    paddingTop: "30pt",
                    border: "0px",
                    minWidth: parseInt(c.minWidth) + "px",
                }}
                key={c.key}
                scope="col"
                minWidth="1800px"
            >
                <Text variant={"xSmall"}>
                    <b>{c.printName}</b>
                </Text>
            </th>
        )
    }

    return (
        <thead style={{ border: "0px" }}>
            <tr style={{ border: "0px" }}>{cols}</tr>
        </thead>
    )
}

function RowData(props) {
    const columns = props.columns
    const item = props.item
    const data = []
    for (const c of columns) {
        let value = item[c.fieldName]
        if (item[Symbol.for(c.fieldName + "_print")] !== undefined) {
            console.log(item[Symbol.for(c.fieldName + "_print")])
            value = item[Symbol.for(c.fieldName + "_print")]
        }
        data.push(
            <td key={c.key}>
                <Text variant={"xSmall"}>{value}</Text>
            </td>
        )
    }

    return <>{data}</>
}

function Rows(props) {
    const columns = props.columns
    const items = props.items
    const rows = []

    for (const i of items) {
        rows.push(
            <tr key={i.key}>
                <RowData item={i} columns={columns} />
            </tr>
        )
    }
    return <tbody>{rows}</tbody>
}

function Footers(props) {
    const columns = props.columns
    const cols = []
    for (const c of columns) {
        cols.push(
            <td style={{ border: "0" }} key={c.key}>
                &nbsp;
            </td>
        )
    }

    return (
        <tfoot>
            <tr style={{ border: "0" }}>{cols}</tr>
        </tfoot>
    )
}
export default function PrintList(props) {
    const columns = props.columns
    const items = props.items
    return (
        <div>
            <table style={{ border: "0" }} width="100%">
                <Headers columns={columns} />
                <Rows columns={columns} items={items} />
                <Footers columns={columns} />
            </table>
        </div>
    )
}
