import React from "react"

import { room as roomValadator } from "@sevenpoint/schema"
import config from "../config"
import { EntityList, EntityForm } from "./Entities"
import { useEntityFromConfig } from "../hooks/Entity"
import { materialsPublicConfig } from "./MaterialsPublic"
import {
    DefaultButton,
    PrimaryButton,
    Stack,
    TooltipHost,
    useTheme,
} from "@fluentui/react"

import { useId } from "@fluentui/react-hooks"

function fi(ui) {
    const feet = Math.floor(ui / 12)
    const inches = ui - feet * 12

    return (
        <>
            {feet}' {inches}"
        </>
    )
}

export function useRoomsConfig() {
    const useMaterialsConfig = useEntityFromConfig(materialsPublicConfig)

    return {
        baseUrl: config.api.baseUrl + "/rooms",
        type: "ROOMS",
        stateKey: "rooms",
        name: "Rooms",
        nameSingle: "Room",
        defaultSortKey: "name",
        allowDelete: false,
        noCalls: true,
        disablePrint: true,
        listColumns: [
            {
                key: "name",
                title: "Name",
            },
            {
                key: "width",
                title: "Width",
                maxWidth: 70,
                minWidth: 70,
                formatter: fi,
            },
        ],
        formFields: [
            {
                label: "Wall Name",
                key: "name",
                type: "text",
            },
            {
                label: "Width",
                key: "width",
                type: "inches",
            },
            {
                label: "Wood Material",
                key: "woodMaterialId",
                type: "entity",
                descrKeyPath: "name",
                iconKeyPath: "imageId",
                valueKeyPath: "id",
                options: useMaterialsConfig.filter([
                    { field: "type", type: "eq", value: "wood" },
                ]),
            },
            {
                label: "Metal Material",

                key: "metalMaterialId",
                type: "entity",
                descrKeyPath: "name",
                colorIconKeyPath: "color",
                valueKeyPath: "id",
                options: useMaterialsConfig.filter([
                    { field: "type", type: "eq", value: "metal" },
                ]),
            },
        ],
        emptyFormValue: { items: [] },
        validator: roomValadator,
        Form: RoomForm,
    }
}

export function RoomList(props) {
    const roomsConfig = useRoomsConfig()
    const useRooms = useEntityFromConfig(roomsConfig)
    const theme = useTheme()
    const tooltipId = useId("tooltip")

    const deleteButtonStyle = {
        root: {
            marginLeft: 0,
            backgroundColor: theme.palette.red,
        },
    }
    if (props.onSelected) {
        roomsConfig.listColumns.push({
            key: "id",
            title: "",
            additionalKeys: ["id"],
            formatter: (value, value1) => {
                const id = value1[0]

                return (
                    <Stack styles={{ root: { width: "50px" } }}>
                        {props.currentRoom !== id && (
                            <DefaultButton
                                onClick={() => {
                                    props.onSelected(id)
                                }}
                            >
                                Select
                            </DefaultButton>
                        )}
                        <TooltipHost
                            content={
                                props.currentRoom === id
                                    ? "You cannot delete the selected room"
                                    : "Deletes this room. This action cannot be reversed"
                            }
                            // This id is used on the tooltip itself, not the host
                            // (so an element with this id only exists when the tooltip is shown)
                            id={tooltipId}
                        >
                            <PrimaryButton
                                styles={deleteButtonStyle}
                                onClick={(e) => {
                                    useRooms.dispatch.remove({ id: id })
                                    e.stopPropagation()
                                }}
                                disabled={props.currentRoom === id}
                            >
                                Delete
                            </PrimaryButton>
                        </TooltipHost>
                    </Stack>
                )
            },
        })
    }
    return <EntityList config={roomsConfig} />
}

export function RoomForm(props) {
    const roomsConfig = useRoomsConfig()

    return <EntityForm {...props} config={roomsConfig} />
}
