import React from "react"

import { Route, Switch } from "react-router"
import { useTheme } from "@fluentui/react"
import useResizer from "../hooks/Resizer"
import { UsersList } from "./Users"
import { ItemList } from "./Items"
import { MaterialsList } from "./Materials"
import { SkuList } from "./Skus"
import { SavesList } from "./Saves"
import { ProjectList } from "./Projects"

function useStyle() {
    const theme = useTheme()
    const mainStyle = {
        backgroundColor: theme.palette.neutralLighterAlt,
    }
    const mainSmallStyle = {
        ...mainStyle,
    }

    return { mainStyle, mainSmallStyle }
}

export default function Main(props) {
    const size = useResizer()

    const { mainStyle, mainSmallStyle } = useStyle()
    return (
        <main
            className={
                size !== "large"
                    ? "main-small main-print"
                    : "main-large main-print"
            }
            style={size !== "large" ? mainSmallStyle : mainStyle}
            data-is-scrollable="true"
        >
            <Switch>
                <Route path="/admin/users">
                    <div className="content-container">
                        <UsersList />
                    </div>
                </Route>
                <Route path="/admin/items">
                    <div className="content-container">
                        <ItemList />
                    </div>
                </Route>
                <Route path="/admin/materials">
                    <div className="content-container">
                        <MaterialsList />
                    </div>
                </Route>
                <Route path="/admin/skus">
                    <div className="content-container">
                        <SkuList />
                    </div>
                </Route>
                <Route path="/admin/saves">
                    <div className="content-container">
                        <SavesList />
                    </div>
                </Route>
                <Route path="/admin/projects">
                    <div className="content-container">
                        <ProjectList />
                    </div>
                </Route>
                <Route path="/">
                    <b>Index</b>
                </Route>
            </Switch>
        </main>
    )
}
