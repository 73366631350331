import React from "react"

import { project as projectValidator } from "@sevenpoint/schema"
import config from "../config"
import { EntityList, EntityForm } from "./Entities"

export const projectTypes = [
    { key: "cannabis", text: "Cannabis" },
    { key: "other", text: "Other" },
]
export const projectPhase = [
    { key: "concept", text: "Concept Design" },
    { key: "schematic", text: "Schematic Design" },
    { key: "dd", text: "Design Development" },
    { key: "specification", text: "Specification" },
    { key: "construction", text: "Construction Admin" },
    { key: "reselection", text: "Reselection/Substitution" },
]

export function useProjectsConfig(simplified = false) {
    const r = {
        baseUrl: config.api.baseUrl + "/projects",
        type: "PROJECTS",
        stateKey: "projects",
        name: "Projects",
        nameSingle: "Project",
        readScope: "read:items",
        writeScope: "write:items",
        defaultSortKey: "contact.email",
        allowAdd: true,

        listColumns: [
            {
                title: "Project Name",
                key: "name",
            },
            {
                key: "contact.email",
                title: "Email",
            },
            {
                key: "contact.name",
                title: "Contact Name",
            },
        ],
        formFields: [
            {
                label: "Project Name",
                key: "name",
                type: "text",
            },
            {
                label: "Project Type",
                key: "type",
                type: "dropdown",
                options: projectTypes,
            },
            {
                label: "Phase",
                key: "phase",
                type: "dropdown",
                options: projectPhase,
            },
            {
                label: "Contact Name",
                key: "contact.name",
                type: "text",
            },
            {
                label: "Email",
                key: "contact.email",
                type: "email",
            },
        ],
        emptyFormValue: { contact: {} },
        validator: projectValidator,
        Form: ProjectForm,
    }
    if (!simplified) {
        r.formFields.push(
            ...[
                {
                    label: "Address",
                    key: "contact.address",
                    type: "text",
                    option: "multiline",
                },
                {
                    label: "Country",
                    key: "contact.country",
                    type: "country",
                },
                {
                    label: "City",
                    key: "contact.city",
                    type: "text",
                },
                {
                    label: "State",
                    key: "contact.state",
                    type: "state",
                    coupledValue: "contact.country",
                },

                {
                    label: "Zip/Postal",
                    key: "contact.postal",
                    type: "text",
                },
                {
                    label: "Phone",
                    key: "contact.phone",
                    type: "tel",
                    coupledValue: "contact.country",
                },
            ]
        )
    }

    return r
}

export function usePublicProjectsConfig() {
    const publicSaveConfig = { ...useProjectsConfig(true) }
    publicSaveConfig.baseUrl = config.api.baseUrl + "/public/project"
    publicSaveConfig.insecure = true

    publicSaveConfig.formFields = [...publicSaveConfig.formFields]

    return publicSaveConfig
}

export function ProjectList(props) {
    const projectsConfig = useProjectsConfig()
    console.log(projectsConfig)
    return <EntityList config={projectsConfig} />
}

export function ProjectForm(props) {
    const projectsConfig = useProjectsConfig()

    return <EntityForm {...props} config={projectsConfig} />
}

export function PublicProjectsForm(props) {
    const publicSaveConfig = usePublicProjectsConfig()
    const onUpsert = (entity, values) => {
        entity.dispatch.upsert(values)
        props.onSubmit(values)
    }
    return (
        <EntityForm {...props} config={publicSaveConfig} onUpsert={onUpsert} />
    )
}
