import React from "react"

import { sku as skuValidator } from "@sevenpoint/schema"
import config from "../config"
import { EntityList, EntityForm } from "./Entities"

export const skusConfig = {
    baseUrl: config.api.baseUrl + "/skus",
    type: "SKUS",
    stateKey: "skus",
    name: "Skus",
    nameSingle: "Sku",
    readScope: "read:items",
    writeScope: "write:items",
    defaultSortKey: "name",
    allowDelete: true,
    importKey: "Serial Base",
    importMaping: {
        "Serial Base": "serialBase",
        Name: "name",
        Price: "price",
    },
    listColumns: [
        {
            key: "serialBase",
            title: "Serial Base",
        },
        {
            key: "name",
            title: "Name",
            minWidth: 400,
            maxWidth: 400,
        },
        {
            key: "price",
            title: "Price",
            formatter: (value) => {
                const formatter = new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                })
                return formatter.format(value / 100)
            },
        },
    ],
    formFields: [
        {
            label: "Serial Base",
            key: "serialBase",
            type: "text",
        },
        {
            label: "Name",
            key: "name",
            type: "text",
        },
        {
            label: "Price",
            key: "price",
            type: "money",
        },
    ],
    emptyFormValue: {},
    validator: skuValidator,
    Form: SkuForm,
}

export function SkuList(props) {
    return <EntityList config={skusConfig} />
}

export function SkuForm(props) {
    return <EntityForm {...props} config={skusConfig} />
}
