import React, { useState } from "react"
import Modal from "./Modal"

export default function UpsertModal(props) {
    const submitRef = props.submitRef
    const AddForm = props.AddForm
    const [hideButtons, setHideButtons] = useState(false)
    const dialogStyles = {
        root: {
            selectors: {
                "@media (min-width: 480px)": {
                    maxWidth: "300px",
                },
            },
        },
    }
    return (
        <Modal
            styles={dialogStyles}
            title={props.buttonLabel}
            onClose={() => {
                props.onClose()
            }}
            maxWidth={300}
        >
            <AddForm
                showButtons={!hideButtons}
                values={props.values}
                submitRef={submitRef}
                onCancel={props.onClose}
                onUpsert={async (entity, values) => {
                    if (values) {
                        entity.dispatch.upsert(values)
                        props.onClose(values)
                    } else {
                        console.error("Failed upsert ")
                    }
                }}
            />
        </Modal>
    )
}
