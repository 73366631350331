import { setupState } from "@sevenpoint/ffsds/src/fe/state"
import { defaultStateHandle } from "@sevenpoint/ffsds/src/fe/state"

export const users = defaultStateHandle("USERS", [])
export const infos = defaultStateHandle("INFOS", [])
export const items = defaultStateHandle("ITEMS", [])
export const materials = defaultStateHandle("MATERIALS", [])

export const images = defaultStateHandle("IMAGES", [])
export const rooms = defaultStateHandle("ROOMS", [], true)
export const skus = defaultStateHandle("SKUS", [])
export const saves = defaultStateHandle("SAVES", [])
export const projects = defaultStateHandle("PROJECTS", [])

const reducers = [
    users.reducer,
    infos.reducer,
    items.reducer,
    materials.reducer,
    images.reducer,
    rooms.reducer,
    skus.reducer,
    saves.reducer,
    projects.reducer,
]
let initialState = {
    users: users.initialState,
    infos: infos.initialState,
    items: items.initialState,
    materials: materials.initialState,
    images: images.initialState,
    rooms: rooms.initialState,
    skus: skus.initialState,
    saves: saves.initialState,
    projects: projects.initialState,
}

const { store, StateProvider, useGlobalState } = setupState(
    initialState,
    reducers
)
export { store, StateProvider, useGlobalState }
