import {
    Box,
    PerspectiveCamera,
    Plane,
    useContextBridge,
    useTexture,
} from "@react-three/drei"
import * as THREE from "three"

import Stage from "../layouts/Stage"
import { Canvas } from "@react-three/fiber"
import React, { Suspense, useEffect, useMemo, useRef, useState } from "react"
import LogoImg from "../images/spiLogo.svg"
import {
    Select,
    EffectComposer,
    Bloom,
    BrightnessContrast,
} from "@react-three/postprocessing"
import ItemRender from "./ItemRender"
import { useEntityFromConfig } from "../hooks/Entity"
import { itemsPublicConfig } from "./ItemsPublic"
import { roomsConfig, useRoomsConfig } from "./Rooms"
import {
    mergeStyles,
    Stack,
    Text,
    ThemeContext,
    ThemeProvider,
    useTheme,
} from "@fluentui/react"
import BaseItemDetailPanel from "./BaseItemDetailPanel"
import { materialsPublicConfig } from "./MaterialsPublic"
import { store } from "../state"
import { Center, RotateLeft, RotateRight } from "../icons/Icons"
import { random } from "lodash"
import { PresentationControls } from "../layouts/PresentationControls"
import config from "../config"

function Wall(props) {
    const textureProps = useTexture({
        map: "3d/textures/drywall/DrywallPainted001_COL_VAR1_4K.jpg",
        roughnessMap: "3d/textures/drywall/DrywallPainted001_GLOSS_1K.jpg",
        aoMap: "3d/textures/drywall/DrywallPainted001_AO_1K.jpg",
        normalMap: "3d/textures/drywall/DrywallPainted001_NRM_1K.jpg",
    })
    if (textureProps.normalMap) {
        const normalMap = textureProps.normalMap
        normalMap.encoding = THREE.sRGBEncoding
    }

    return (
        <Plane
            receiveShadow
            args={[props.width, 9 * 12]}
            position={[0, (9 * 12) / 2, 0]}
        >
            <meshPhongMaterial
                attach="material"
                {...textureProps}
                color="white"
                normalMapType={THREE.TangentSpaceNormalMap}
                normalScale={0.5}
            />
        </Plane>
    )
}
function Floor(props) {
    const textureProps = useTexture({
        map: "3d/textures/concrete_polished/ConcretePolished001_COL_1K.jpg",
        normalMap:
            "3d/textures/concrete_polished/ConcretePolished001_NRM_1K.jpg",
        roughnessMap:
            "3d/textures/concrete_polished/ConcretePolished001_GLOSS_1K.jpg",
        aoMap: "3d/textures/concrete_polished/ConcretePolished001_AO_1K.jpg",
    })

    return (
        <Plane
            receiveShadow
            args={[props.width, 5 * 12]}
            position={[0, 0, (5 * 12) / 2]}
            rotation={[Math.PI / -2, 0, 0]}
        >
            <meshPhongMaterial {...textureProps} />
        </Plane>
    )
}

function Logo(props) {
    const style = mergeStyles({
        userSelect: "none",
        position: "fixed",
        bottom: "5px",
        left: "5px",
        height: "200px",
        width: "200px",
        zIndex: "9",
    })
    return (
        <div className={style}>
            <img src={LogoImg} alt="Logo" />
        </div>
    )
}
function RoomState(props) {
    const useRooms = useEntityFromConfig(useRoomsConfig())

    const rooms = useRooms.filter([])

    const style = mergeStyles({
        userSelect: "none",
        position: "fixed",
        bottom: "15px",
        right: "40px",
        height: "40px",
        width: "600px",
        zIndex: "9",
        textAlign: "right",
    })
    return (
        <div className={style}>
            <Text>
                <b>THE MODULATOR</b> |{" "}
                {props.room.name + " of " + rooms.length + " plans"}
            </Text>
        </div>
    )
}

function BoxProp(props) {
    return (
        <Select enabled={props.selected === props.name}>
            <Box
                {...props}
                onClick={(e) => {
                    props.setSelected(props.name)
                }}
            ></Box>
        </Select>
    )
}

function Fixtures(props) {
    const [selected, setSelected] = useState(false)
    const [modal, setModal] = useState(null)
    const useMaterials = useEntityFromConfig(materialsPublicConfig)
    const [positions, setPositions] = useState({})
    useEffect(() => {
        props.setModal(modal)
    }, [modal, props.setModal])

    if (!props.items) {
        return null
    }
    const items = []

    for (const i of props.items) {
        const refItem = props.allItems.filter((e) => e.id === i.itemId)

        const materials = useMaterials.filter([
            {
                field: "id",
                type: "eq",
                value:
                    refItem[0].materialType === "wood"
                        ? props.room.Id
                        : props.room.metalMaterialId,
            },
        ])

        const woodMaterial = useMaterials.filter([
            {
                field: "id",
                type: "eq",
                value: props.room.woodMaterialId,
            },
        ])[0] //usually not safe
        const metalMaterial = useMaterials.filter([
            {
                field: "id",
                type: "eq",
                value: props.room.metalMaterialId,
            },
        ])[0] //Usually not safe

        items.push(
            <Select enabled={selected === i.id}>
                <group
                    key={i.id}
                    ref={selected === i.id ? props.focusedItemRef : null}
                >
                    <ItemRender
                        viewPosition={props.viewPosition}
                        base={!props.notBase}
                        allItems={props.allItems}
                        items={props.items}
                        id={i.itemId}
                        item={i}
                        room={props.room}
                        disableClick={props.disableClick}
                        material={
                            materials && materials[0] ? materials[0] : null
                        }
                        woodMaterial={woodMaterial}
                        metalMaterial={metalMaterial}
                        polygonOffset={props.polygonOffset}
                        onClick={(options) => {
                            if (props.disableClick) {
                                return
                            }
                            setSelected(i.id)
                            props.setFocusedItem(options.groupRef)
                            setModal(
                                <BaseItemDetailPanel
                                    id={i.itemId}
                                    room={props.room}
                                    item={i}
                                    onClose={() => {
                                        setModal(null)
                                        props.setFocusedItem(null)
                                        setSelected(false)
                                    }}
                                    onSave={() => {
                                        setModal(null)
                                        props.setFocusedItem(null)
                                        setSelected(false)
                                    }}
                                    material={woodMaterial}
                                />
                            )
                        }}
                        position={[
                            i.x + props.position ? props.position[0] : 0,
                            i.z,
                            i.y + props.position ? props.position[3] : 0,
                        ]}
                        positionLimit={[
                            [props.width / -2, props.width / 2],
                            [0, 0],
                            [0, 0],
                        ]}
                        setPosition={(pos) => {
                            setPositions({ ...positions, [i.id]: pos })
                        }}
                    />
                    {i.subitems && i.subitems.length > 0 && (
                        <group
                            position={[
                                positions[i.id] ? positions[i.id][0] : i.x,
                                i.z,
                                i.y,
                            ]}
                        >
                            <Fixtures
                                viewPosition={props.viewPosition}
                                notBase
                                setModal={() => {}}
                                allItems={props.allItems}
                                items={i.subitems}
                                room={props.room}
                                setFocusedItem={(item) => {
                                    return
                                }}
                                disableClick={true}
                                width={props.width}
                                polygonOffset="-1"
                                materialId={i.materialId}
                            />
                        </group>
                    )}
                </group>
            </Select>
        )
    }

    return <group>{items}</group>
}

function Camera(props) {
    return (
        <PerspectiveCamera
            makeDefault
            zoom={props.focusedItem ? 0.9 : props.zoom}
            position={[0, 0, 0]}
            filmGauge={35}
            near={20}
            far={1000}
        />
    )
}

function ControlButtons(props) {
    const style = mergeStyles({
        userSelect: "none",
        position: "fixed",
        top: "70px",
        right: "40px",
        height: "200px",
        width: "100px",
        zIndex: "10",
    })
    const containerStyle = mergeStyles({
        width: "40px",
        cursor: "pointer",
    })
    return (
        <div className={style}>
            <Stack horizontal>
                <div
                    className={containerStyle}
                    onClick={() => {
                        props.setCameraPosition([0, Math.PI / 2, 0])
                        props.setPosition("left")
                    }}
                >
                    <RotateLeft />
                </div>
                <div
                    className={containerStyle}
                    onClick={() => {
                        props.setCameraPosition([0, 0, 0])
                        props.setPosition("front")
                    }}
                >
                    <Center />
                </div>
                <div
                    className={containerStyle}
                    onClick={() => {
                        props.setCameraPosition([0, -Math.PI / 2, 0])
                        props.setPosition("right")
                    }}
                >
                    <RotateRight />
                </div>
            </Stack>
        </div>
    )
}

function Controls(props) {
    const [pi, setPi] = useState(Math.PI)
    useEffect(() => {
        setTimeout(() => {
            setPi(Math.PI - random(1) / 100)
        }, 800)
    }, [props.cameraPosition])
    return (
        <PresentationControls
            makeDefault
            global={true} // Spin globally or by dragging the model
            cursor={true} // Whether to toggle cursor style on drag
            snap={true} // Snap-back to center (can also be a spring config)
            speed={1} // Speed factor
            zoom={1} // Zoom factor when half the polar-max is reached
            rotation={[...props.cameraPosition]} // Default rotation
            polar={[0, Math.PI / 2]} // Vertical limits
            azimuth={[-pi, pi]} // Horizontal limits
            config={{ mass: 1, tension: 170, friction: 26 }} // Spring config
        >
            {props.children}
        </PresentationControls>
    )
}

export function DesignStageCanvas(props) {
    const width = props.width
    const useItems = useEntityFromConfig(itemsPublicConfig)
    const items = useItems.filter([])
    const [cameraPosition, setCameraPosition] = useState([0, 0, 0])
    const [position, setPosition] = useState("front")

    const [focusedItem, _setFocusedItem] = useState(null)
    function setFocusedItem(item) {
        _setFocusedItem(item)
    }

    const ContextBridge = useContextBridge(store, ThemeContext)

    return (
        <>
            <Logo width={width} />
            {config.showControls && (
                <ControlButtons
                    cameraPosition={cameraPosition}
                    setCameraPosition={setCameraPosition}
                    setPosition={setPosition}
                />
            )}
            <RoomState room={props.room} width={width} />
            <Canvas
                shadows
                colorManagement
                style={{ backgroundColor: "#fffef2", touchAction: "none" }}
                dpi={window.devicePixelRatio}
                sRGB={true}
                ref={props.canvasRef}
                gl={
                    props.preserveDrawingBuffer
                        ? { preserveDrawingBuffer: true }
                        : {}
                }
            >
                <ContextBridge>
                    <Camera focusedItem={focusedItem} zoom={props.zoom} />

                    <Suspense fallback={null}>
                        <Controls
                            cameraPosition={cameraPosition}
                            room={props.room}
                        >
                            <Stage
                                shadows={true}
                                adjustCamera={true}
                                intensity={1}
                                preset="upfront"
                                contactShadow={false}
                                focusedItem={focusedItem ? focusedItem : null}
                            >
                                <group position={[0, 0, 0]}>
                                    <Floor width={width} />
                                    <Wall width={width} />
                                    <Fixtures
                                        viewPosition={position}
                                        setModal={props.setModal}
                                        allItems={items}
                                        items={props.room.items}
                                        room={props.room}
                                        setFocusedItem={(item) => {
                                            setFocusedItem(item)
                                        }}
                                        width={width}
                                    />
                                </group>
                            </Stage>
                            <EffectComposer>
                                {props.lights && (
                                    <Bloom
                                        width={30}
                                        height={40}
                                        kernelSize={3}
                                    />
                                )}
                                <BrightnessContrast
                                    brightness={0.1} // brightness. min: -1, max: 1
                                    contrast={0} // contrast: min -1, max: 1
                                />
                            </EffectComposer>
                        </Controls>
                    </Suspense>
                </ContextBridge>
            </Canvas>
        </>
    )
}

export default function DesignStage(props) {
    const [modal, setModal] = useState(null)

    return (
        <>
            {modal}
            <div style={{ height: "90vh" }}>
                <DesignStageCanvas {...props} setModal={setModal} />
            </div>
        </>
    )
}
