import React from "react"

import { material as materialValaditor } from "@sevenpoint/schema"
import config from "../config"

export const materialsPublicConfig = {
    baseUrl: config.api.baseUrl + "/public/materials",
    type: "MATERIALS",
    stateKey: "materials",
    name: "Materials",
    nameSingle: "Material",
    readScope: "read:items",
    writeScope: "write:items",
    defaultSortKey: "name",
    allowDelete: false,
    validator: materialValaditor,
    insecure: true,
}
