import React from "react"

import { material as materialValidator } from "@sevenpoint/schema"
import config from "../config"
import { EntityList, EntityForm } from "./Entities"

export const materialsConfig = {
    baseUrl: config.api.baseUrl + "/materials",
    type: "MATERIALS",
    stateKey: "materials",
    name: "Materials",
    nameSingle: "Material",
    readScope: "read:items",
    writeScope: "write:items",
    defaultSortKey: "name",
    allowDelete: true,
    listColumns: [
        {
            key: "name",
            title: "Name",
            minWidth: 400,
            maxWidth: 400,
        },
        {
            key: "serialBase",
            title: "Serial Base",
        },
        {
            key: "type",
            title: "Type",
        },
    ],
    formFields: [
        {
            label: "Name",
            key: "name",
            type: "text",
        },
        {
            label: "Image",
            key: "imageId",
            type: "image",
            onlyOne: true,
            allowDelete: true,
        },

        {
            label: "Serial Base",
            key: "serialBase",
            type: "text",
        },
        {
            label: "Color",
            key: "color",
            type: "color",
        },
        {
            label: "Type",
            key: "type",
            type: "dropdown",
            options: [
                { key: "wood", text: "Wood" },
                { key: "metal", text: "Metal" },
            ],
        },
    ],
    emptyFormValue: {},
    validator: materialValidator,
    Form: MaterialForm,
}

export function MaterialsList(props) {
    return <EntityList config={materialsConfig} />
}

export function MaterialForm(props) {
    return <EntityForm {...props} config={materialsConfig} />
}
