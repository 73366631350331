import React, { useState, useEffect, useMemo } from "react"
import "../css/fluentGlobal.css"
import { BrowserRouter, Route, Switch, useParams } from "react-router-dom"
import {
    Stack,
    Layer,
    MessageBar,
    MessageBarType,
    DefaultPalette,
    mergeStyles,
    AnimationClassNames,
    getTheme,
    useTheme,
    ThemeProvider,
} from "@fluentui/react"
import Header from "./Header"
import Auth0ProdiverProspecer from "./Auth0Provider"

import Protected from "./Protected"
import useResizer from "../hooks/Resizer"
import Main from "./Main"
import Nav from "./Nav"

function useStyle() {
    const theme = useTheme()
    const navStyles = {
        root: {
            background: theme.palette.neutralLight,
            color: theme.palette.black,
            display: "flex",
            height: "100%",
            minHeight: "100%",
            justifyContent: "center",
            overflow: "auto",
            width: 250,
        },
    }

    const navStylesSmall = { root: { ...navStyles.root, width: 40 } }
    const stackStyles = {
        height: "100%",
    }

    const mainStackStyles = {
        margin: 0,
        height: "100%",
    }
    return { mainStackStyles, navStyles, navStylesSmall, stackStyles }
}

export default function AuthWrappedMain(props) {
    return (
        <Auth0ProdiverProspecer>
            <AuthMain {...props} />
        </Auth0ProdiverProspecer>
    )
}

function AuthMain(props) {
    const size = useResizer()
    const theme = getTheme()

    const [messages, setMessages] = useState([])
    const { navStyles, navStylesSmall, mainStackStyles, stackStyles } =
        useStyle()

    useEffect(() => {
        const f = (e) => {
            const message = { type: e.detail.type, message: e.detail.message }
            messages.push(message)
            setMessages([...messages])
            setTimeout(() => {
                messages.splice(
                    messages.findIndex((m) => m === message),
                    1
                )
                setMessages([...messages])
            }, 5000)
        }
        document.addEventListener("infoMessage", f)
        return () => {
            document.removeEventListener("infoMessage", f)
        }
    }, [messages])
    const contentClass = mergeStyles([
        {
            color: theme.palette.white,
            lineHeight: "50px",
            bottom: 0,
            position: "fixed",
            right: 0,
            textAlign: "center",
            minWidth: "300px",
            padding: 16,
        },

        AnimationClassNames.fadeIn100,
    ])

    const messageBarStyles = {
        root: { margin: 5 },
    }
    return (
        <Stack style={{ height: "100%", width: "100%" }}>
            <Header />
            <Protected>
                <Switch>
                    <Stack
                        disableShrink
                        horizontal
                        tokens={{ childrenGap: 5 }}
                        style={stackStyles}
                    >
                        <Stack.Item
                            disableShrink
                            styles={
                                size !== "large" ? navStylesSmall : navStyles
                            }
                            className="no-print"
                        >
                            <Nav size={size} />
                        </Stack.Item>
                        <Stack.Item
                            style={mainStackStyles}
                            grow={1}
                            className="main-print"
                        >
                            <Main />
                        </Stack.Item>
                    </Stack>
                </Switch>
                {messages.length > 0 && (
                    <Layer>
                        <div className={contentClass}>
                            {messages.map((message, i) => {
                                return (
                                    <MessageBar
                                        key={i}
                                        styles={messageBarStyles}
                                        messageBarType={
                                            message.type === "error"
                                                ? MessageBarType.error
                                                : MessageBarType.info
                                        }
                                    >
                                        {message.message}
                                    </MessageBar>
                                )
                            })}
                        </div>
                    </Layer>
                )}
            </Protected>
        </Stack>
    )
}
