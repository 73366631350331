import React, { useRef } from "react"

import { CommandBar, Stack } from "@fluentui/react"
import { useState } from "react"
import { useEntityFromConfig } from "../hooks/Entity"
import DesignStage from "./DesignStage"
import DimensionsModal from "./DimensionsModal"
import { roomsConfig, useRoomsConfig } from "./Rooms"
import { useGlobalState } from "../state"
import { itemsPublicConfig } from "./ItemsPublic"
import PublicTopBar from "./PublicTopBar"
import { PublicProjectsForm, usePublicProjectsConfig } from "./Projects"

function FirstRoom(props) {
    const roomsConfig = useRoomsConfig()
    const useRooms = useEntityFromConfig(roomsConfig)
    const useProjects = useEntityFromConfig(usePublicProjectsConfig(true))

    const ProjectForm = PublicProjectsForm

    const Form = roomsConfig.Form
    const submitRef = useRef()
    const stackStyle = { root: { marginLeft: "15px" } }

    const projects = useProjects.filter([])

    const enterProject = projects.length < 1

    return (
        <Stack styles={stackStyle} horizontal>
            <Stack.Item align="end">
                <div
                    style={{
                        width: "35vw",
                        paddingLeft: "5vw",
                        paddingRight: "5vw",
                        display: "flex",
                        justifyContent: "flex-end",
                    }}
                >
                    <div
                        style={{
                            maxWidth: "300px",
                        }}
                    >
                        <h1>
                            THE
                            <br />
                            <div className="yellow">MODULE SYSTEM</div>
                        </h1>
                        <h3 className="green">
                            THE MODULE SYSTEM IS OUR PROPRIETARY MODULAR FIXTURE
                            DISPLAY COLLECTION. OUR PRE-ENGINEERED MODULES ARE
                            DESIGNED SPECIFICALLY FOR THE RETAIL CANNABIS
                            INDUSTRY.
                        </h3>
                        <p>
                            Available in standard and custom colours, a wide
                            range of finishes and infinite configurations, just
                            plug in your brand and floor plan and you’ve got a
                            premium cannabis retail shop. Using this
                            pre-designed and engineered module system, we can
                            design and manufacture fixtures and displays that
                            are unique to your brand, without a massive timeline
                            or budget.
                        </p>
                    </div>
                </div>
            </Stack.Item>
            <div style={{ paddingRight: "1vw" }}>
                {enterProject && (
                    <>
                        <h1>Enter Project Information.</h1>
                        <ProjectForm
                            showButtons
                            submitRef={submitRef}
                            onUpsert={async (entity, values) => {
                                if (values) {
                                    entity.dispatch.upsert(values)
                                    ///props.setCurrentRoom(values.id)
                                } else {
                                    console.error("Failed upsert ")
                                }
                            }}
                            onSubmit={() => {}}
                        />
                    </>
                )}
                {!enterProject && (
                    <>
                        <h1>Begin designing your first wall.</h1>
                        <Form
                            showButtons
                            values={props.values}
                            submitRef={submitRef}
                            onUpsert={async (entity, values) => {
                                if (values) {
                                    entity.dispatch.upsert(values)
                                    props.setCurrentRoom(values.id)
                                } else {
                                    console.error("Failed upsert ")
                                }
                            }}
                        />
                    </>
                )}
            </div>
        </Stack>
    )
}

export default function PublicMain(props) {
    const [zoom, setZoom] = useState(1.5)
    const [lights, setLights] = useState(false)

    const useRooms = useEntityFromConfig(useRoomsConfig())
    const rooms = useRooms.filter([])

    const [currentRoom, setCurrentRoom] = useState(
        rooms && rooms[0] ? rooms[0].id : null
    )

    const showModel = rooms && rooms[0] ? false : true

    const selectedRoom = currentRoom
        ? useRooms.filter([{ field: "id", type: "eq", value: currentRoom }])[0]
        : null

    return (
        <>
            {showModel && <FirstRoom setCurrentRoom={setCurrentRoom} />}
            {!showModel && selectedRoom !== null && (
                <>
                    <PublicTopBar
                        zoom={zoom}
                        room={selectedRoom}
                        setZoom={setZoom}
                        setLights={setLights}
                        lights={lights}
                        currentRoom={selectedRoom.id}
                        onRoomSelected={(id) => {
                            console.log(id)
                            setCurrentRoom(id)
                        }}
                    />

                    <DesignStage
                        room={selectedRoom}
                        width={selectedRoom.width}
                        zoom={zoom}
                        setLights={setLights}
                        lights={lights}
                    />
                </>
            )}
        </>
    )
}
