import {
    Checkbox,
    DefaultButton,
    DialogType,
    Icon,
    Image,
    ImageFit,
    Label,
    mergeStyles,
    PrimaryButton,
    Separator,
    Stack,
    Text,
    useTheme,
} from "@fluentui/react"
import { useState, useEffect } from "react"
import config from "../config"
import { ObjStage } from "../controls/Image"
import { useEntityFromConfig } from "../hooks/Entity"
import ConfirmDialog from "../layouts/ConfimDialog"
import Panel from "../layouts/Panel"
import { items } from "../state"
import { itemsPublicConfig } from "./ItemsPublic"
import { materialsPublicConfig } from "./MaterialsPublic"
import { useRoomsConfig } from "./Rooms"
import { v4 as uuidv4 } from "uuid"
import { cloneDeep } from "lodash"

const buttonControlStyle = {
    width: "60px",
    height: "80px",
    marginRight: 5,
    marginBottom: "41pt",
    position: "relative",
    cursor: "pointer",
    textAlign: "center",
}
const buttonControlActiveStyle = {
    ...buttonControlStyle,
    "::after": {
        content: "' '",
        position: "absolute",
        left: "10.5px",
        right: "10.5px",
        bottom: "0px",
        top: "45px",
        height: "2px",
        background: "rgb(17, 17, 17)",
    },
}

const buttonControlActiveLargeStyle = {
    ...buttonControlStyle,
    "::after": {
        content: "' '",
        position: "absolute",
        left: "10.5px",
        right: "10.5px",
        bottom: "0px",
        top: "92px",
        height: "2px",
        background: "rgb(17, 17, 17)",
    },
}

function useUpdateItemInRoom() {
    const useRooms = useEntityFromConfig(useRoomsConfig())
    return (room, item) => {
        const index = room.items.findIndex((i) => i.id === item.id)

        if (index !== -1) {
            room.items[index] = item
            useRooms.dispatch.upsert({ ...room })
        }
    }
}

function RemoveItemButton(props) {
    const item = props.item
    const type = props.type
    const updater = useUpdateItemInRoom()
    const room = props.room
    const buttonStyle = {
        root: { border: "none" },
    }

    function remove() {
        let index = null
        console.log(item, type)
        do {
            index = item.subitems.findIndex((e) => e.type === type)
            console.log(index)
            if (index > -1) {
                item.subitems.splice(index, 1)
            }
        } while (index !== -1)
        const event = new Event("clear_" + type)
        document.body.dispatchEvent(event)
    }

    return (
        <DefaultButton
            text="Clear"
            onClick={() => {
                remove()
                updater(room, item)
            }}
            styles={buttonStyle}
            allowDisabledFocus
        />
    )
}

function SubItem(props) {
    const useItems = useEntityFromConfig(itemsPublicConfig)
    const useMaterials = useEntityFromConfig(materialsPublicConfig)
    const useRoom = useEntityFromConfig(useRoomsConfig())
    const updater = useUpdateItemInRoom()
    const [selected, setSelected] = useState(null)
    const type = props.type
    const rooms = useRoom.filter([
        { field: "id", type: "eq", value: props.room.id },
    ])

    useEffect(() => {
        const f = () => {
            setSelected(null)
        }
        document.body.addEventListener("clear_" + type, f)
        return () => {
            document.body.removeEventListener("clear_" + type, f)
        }
    }, [])

    const item = rooms[0]
        ? rooms[0].items.filter((i) => i.id === props.item.id)[0]
        : null

    const baseItems = useItems.filter([
        { field: "id", type: "eq", value: item.itemId },
        { field: "name", type: "sort", direction: "ASC" },
    ])
    const sortFn = (o1, o2) => {
        const i1 = useItems.filter([{ field: "id", type: "eq", value: o1.id }])
        const i2 = useItems.filter([{ field: "id", type: "eq", value: o2.id }])
        if (i1[0] && i2[0]) {
            return i1[0].name.localeCompare(i2[0].name)
        }
        return 0
    }

    useEffect(() => {
        if (!item.subitems) {
            return
        }
        if (selected) {
            return
        }
        const result = props.itemId
            ? item.subitems.filter((i) => i.itemId === props.itemId)
            : item.subitems.filter((i) => i.type === type).sort(sortFn)

        if (result && result[0] && baseItems[0]) {
            const thing = baseItems[0].subitems.filter(
                (i) => i.id === result[0].itemId
            )
            setSelected(thing[0])
        }
    }, [])

    if (!baseItems[0]) {
        return null
    }
    const subItemIds = props.itemId
        ? baseItems[0].subitems.filter((i) => i.id === props.itemId)
        : baseItems[0].subitems.filter((i) => i.type === type).sort(sortFn)
    const style = mergeStyles({ ...buttonControlStyle, height: 90, width: 140 })

    const iconClass = mergeStyles({
        fontSize: 40,
        height: 80,
        width: 80,
        padding: 20,
        margin: "5px 0",
    })
    const cssInactive = mergeStyles({
        ...buttonControlStyle,
        height: 125,
        width: 139,
    })
    const cssActive = mergeStyles({
        ...buttonControlActiveLargeStyle,
        height: 125,
        width: 139,
    })

    const woodMaterials = useMaterials.filter([
        {
            field: "id",
            type: "eq",
            value: rooms[0].woodMaterialId,
        },
    ])
    const metalMaterials = useMaterials.filter([
        {
            field: "id",
            type: "eq",
            value: rooms[0].metalMaterailId,
        },
    ])
    const buttons = []
    for (const i of subItemIds) {
        const id = i.id
        const fitem = useItems.filter([{ field: "id", type: "eq", value: id }])
        if (fitem[0]) {
            buttons.push(
                <div
                    className={i === selected ? cssActive : cssInactive}
                    onClick={() => {
                        setSelected(i)

                        const p = i.ypositions.split(",")
                        if (p.length > 1) {
                            return
                        }

                        const index = item.subitems.findIndex(
                            (e) => e.type === props.type
                        )
                        if (index > -1) {
                            item.subitems.splice(index, 1)
                        }

                        const woodMaterial = woodMaterials[0]

                        const positions = i.ypositions.split(",")
                        if (positions.length === 1) {
                            item.subitems.push({
                                id: uuidv4(),
                                itemId: fitem[0].id,
                                x: 0,
                                y: 0,
                                z: p[0] ? p[0] : 0,
                                type: props.type,
                                materialId: woodMaterial.id,
                            })

                            updater(props.room, item)
                        }
                    }}
                >
                    <Stack>
                        {fitem[0].imageId && (
                            <Image
                                key={id}
                                imageFit={ImageFit.centerContain}
                                height="90px"
                                width="139px"
                                src={config.images.baseUrl + fitem[0].imageId}
                            />
                        )}
                        {fitem[0].imageId === null && (
                            <ObjStage
                                key={id}
                                height="90px"
                                width="139px"
                                zoom={1}
                                gltfPath={
                                    config.images.baseUrl + fitem[0].modelId
                                }
                                woodMaterial={woodMaterials[0]}
                                metalMaterial={metalMaterials[0]}
                                material={metalMaterials[0]}
                            />
                        )}
                        <Text>
                            <div
                                style={{
                                    marginTop: "8pt",
                                    textTransform: "capitalize",
                                    marginBottom: "10pt",
                                }}
                            >
                                {fitem[0].name.toLowerCase()}
                            </div>
                        </Text>
                    </Stack>
                </div>
            )
        }
    }
    const locations = []
    if (selected) {
        const positions = selected.ypositions.split(",").reverse()
        if (positions.length > 1) {
            for (const p of positions) {
                const d =
                    item.subitems.filter(
                        (i) =>
                            (props.itemId
                                ? i.itemId === props.itemId
                                : i.type === type) &&
                            i.z === Number.parseFloat(p)
                    ).length > 0
                locations.push(
                    <Stack.Item>
                        <Checkbox
                            label={"position " + p}
                            checked={
                                item.subitems.findIndex((e) => {
                                    return (
                                        e.z === Number.parseFloat(p) &&
                                        e.itemId ===
                                            useItems.filter([
                                                {
                                                    field: "id",
                                                    type: "eq",
                                                    value: selected.id,
                                                },
                                            ])[0].id
                                    )
                                }) !== -1
                            }
                            onChange={(ev, isChecked) => {
                                const fitem = useItems.filter([
                                    {
                                        field: "id",
                                        type: "eq",
                                        value: selected.id,
                                    },
                                ])
                                if (isChecked) {
                                    const index = item.subitems.findIndex(
                                        (e) => {
                                            return e.z === Number.parseFloat(p)
                                        }
                                    )

                                    if (index > -1) {
                                        item.subitems.splice(index, 1)
                                    }

                                    item.subitems.push({
                                        id: uuidv4(),
                                        itemId: fitem[0].id,
                                        x: 0,
                                        y: 0,
                                        z: Number.parseFloat(p),
                                        type: props.type,
                                    })

                                    updater(props.room, item)
                                } else {
                                    const index = item.subitems.findIndex(
                                        (e) => {
                                            return (
                                                e.z === Number.parseFloat(p) &&
                                                e.itemId === fitem[0].id
                                            )
                                        }
                                    )

                                    if (index > -1) {
                                        item.subitems.splice(index, 1)
                                    }
                                    updater(props.room, { ...item })
                                }
                            }}
                        />
                    </Stack.Item>
                )
            }
        }
    }

    const stackCheckStyle = { root: { marginBottom: 30, paddingTop: 20 } }

    const containerSize = mergeStyles({ paddingBottom: "10" })
    return (
        <Stack horizontal>
            <Stack className={containerSize} horizontal wrap>
                {buttons}
            </Stack>
            <Stack styles={stackCheckStyle}>{locations}</Stack>
        </Stack>
    )
}

function Materials(props) {
    const useItems = useEntityFromConfig(itemsPublicConfig)
    const useMaterials = useEntityFromConfig(materialsPublicConfig)
    const useRoom = useEntityFromConfig(useRoomsConfig())
    const updater = useUpdateItemInRoom()

    const rooms = useRoom.filter([
        { field: "id", type: "eq", value: props.room.id },
    ])
    if (!(rooms && rooms[0])) {
        return null
    }
    const room = rooms[0]

    const item = props.item
    const baseItem = useItems.filter([
        { field: "id", type: "eq", value: item.itemId },
    ])
    const materialIds = baseItem && baseItem[0] ? baseItem[0].materialIds : []
    const materials = useMaterials.filter([
        { field: "id", type: "in", value: materialIds.map((m) => m.id) },
    ])

    const matButtons = []
    for (const m of materials) {
        const css = mergeStyles({
            ...(item.materialId === m.id
                ? buttonControlActiveStyle
                : buttonControlStyle),
            backgroundColor: m.color,
        })
        matButtons.push(
            <div
                onClick={() => {
                    item.materialId = m.id
                    updater(room, item)
                }}
                className={css}
                key={m.id}
            ></div>
        )
    }

    return (
        <div>
            <Label>Finishes</Label>
            <Stack horizontal>{matButtons}</Stack>
        </div>
    )
}

function PanelHeading(props) {
    const headingStyle = {
        paddingBottom: "4px",
        paddingTop: "8px",
        display: "block",
    }
    const room = props.room
    const item = props.item
    const type = props.type

    return (
        <Text style={headingStyle} variant="large">
            <Stack horizontal>
                <Stack.Item grow={3} align="center">
                    <Text style={headingStyle} variant="large">
                        <b style={{ textTransform: "uppercase" }}>
                            {props.title}
                        </b>
                    </Text>
                </Stack.Item>
                <Stack.Item align="center">
                    <RemoveItemButton room={room} type={type} item={item} />
                </Stack.Item>
            </Stack>
        </Text>
    )
}

export default function BaseItemDetailPanel(props) {
    const room = props.room
    const theme = useTheme()
    const useRooms = useEntityFromConfig(useRoomsConfig())
    const useItems = useEntityFromConfig(itemsPublicConfig)

    const [model, setModal] = useState(null)
    const deleteButtonStyle = {
        root: {
            marginLeft: 0,
            backgroundColor: theme.palette.red,
        },
    }

    function onDelete(id) {
        const itemIndex = room.items.findIndex((i) => i.id === id)

        if (itemIndex > -1) {
            room.items.splice(itemIndex, 1)
            useRooms.dispatch.upsert(room)
        }
        props.onClose()
    }

    function onDuplicate(id) {
        const itemIndex = room.items.findIndex((i) => i.id === id)

        if (itemIndex > -1) {
            const room2 = { ...room }
            const bi = cloneDeep(room2.items)
            //console
            bi.push({
                id: uuidv4(),
                itemId: room.items[itemIndex].itemId,
                x: 1,
                y: 0,
                z: 0,
                subitems: [...room.items[itemIndex].subitems],
            })
            room2.items = bi
            useRooms.dispatch.upsert(room2)
        }
        props.onClose()
    }

    const rooms = useRooms.filter([
        { field: "id", type: "eq", value: props.room.id },
    ])
    const item = rooms[0]
        ? rooms[0].items.filter((i) => i.id === props.item.id)[0]
        : null

    const baseItems = useItems.filter([
        { field: "id", type: "eq", value: item.itemId },
        { field: "name", type: "sort", direction: "ASC" },
    ])

    const sortFn = (o1, o2) => {
        const i1 = useItems.filter([{ field: "id", type: "eq", value: o1.id }])
        const i2 = useItems.filter([{ field: "id", type: "eq", value: o2.id }])
        if (i1[0] && i2[0]) {
            return i1[0].name.localeCompare(i2[0].name)
        }
        return 0
    }

    const shelfItmes = baseItems[0].subitems
        .filter((i) => i.type === "shelf")
        .sort(sortFn)
    const displayItems = baseItems[0].subitems
        .filter((i) => i.type === "display")
        .sort(sortFn)
    const headingStyle = {
        paddingBottom: "4px",
        paddingTop: "8px",
        display: "block",
    }
    return (
        <>
            <Panel
                onClose={props.onClose}
                onSave={props.onSave}
                lightDismiss={true}
                isOpen={true}
                hideOverlay={true}
                title={"Edit Frame"}
                hideButtons
            >
                {model}

                <Label
                    styles={{
                        root: {
                            marginTop: 40,
                        },
                    }}
                >
                    Bay Actions
                </Label>
                <PrimaryButton
                    onClick={() => {
                        setModal(
                            <ConfirmDialog
                                dialogContentProps={{
                                    title: "Delete?",
                                    closeButtonAriaLabel: "Close",
                                    subText:
                                        "Deleting this item will remove the items and ALL of the contents?",
                                }}
                                actionText="Delete"
                                dismissText="Take no action"
                                onConfirm={() => {
                                    props.onClose()
                                    setTimeout(() => {
                                        onDelete(props.item.id)
                                    }, 500)
                                }}
                                onDismiss={() => {
                                    setModal(null)
                                }}
                            />
                        )
                    }}
                    styles={deleteButtonStyle}
                >
                    Delete
                </PrimaryButton>
                <PrimaryButton
                    onClick={() => {
                        onDuplicate(props.item.id)
                    }}
                >
                    Duplicate
                </PrimaryButton>
                <Separator />
                <PanelHeading
                    title={"Wall Graphic Header"}
                    room={room}
                    item={item}
                    type={"header"}
                />
                <SubItem {...props} type="header" />

                <PanelHeading
                    title={"Wall Shelves"}
                    room={room}
                    type={"shelf"}
                    item={item}
                />
                {shelfItmes.map((object, i) => {
                    return (
                        <SubItem {...props} type="shelf" itemId={object.id} />
                    )
                })}
                <PanelHeading
                    title={"Wall Display Boxes"}
                    room={room}
                    type={"display"}
                    item={item}
                />

                {displayItems.map((object, i) => {
                    return (
                        <SubItem {...props} type="display" itemId={object.id} />
                    )
                })}

                <PanelHeading
                    title={"Wall Storage"}
                    room={room}
                    type={"cabnet"}
                    item={item}
                />

                <SubItem {...props} type="cabnet" />
            </Panel>
        </>
    )
}
