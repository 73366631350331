import React, { useState } from "react"
import axios from "axios"
import { useAuth0 } from "@auth0/auth0-react"
import { valueInKeyPath } from "../shared/keypath.js"
import { union } from "lodash"

function baseHasParams(base) {
    if (base.indexOf("?") !== -1) {
        return true
    }
    return false
}

function filtersToParams(filters, append = false) {
    let result = ""

    for (const f of filters) {
        //Only eq supported
        if (result === "") {
            if (append) {
                result = "&"
            } else {
                result = "?"
            }
        } else {
            result = result + "&"
        }
        result = result + f.field + "=" + f.value
    }

    return result
}
export function useCalls(appConfig, config, state) {
    if (config.noCalls) {
        return noopDispatch(config, state)
    }
    const entityConfig = config
    const auto0Config = appConfig.auth0
    const { getAccessTokenSilently } = useAuth0()

    const fetch = async function (filters) {
        const accessToken = await getAccessTokenSilently({
            audience: auto0Config.audience,
            scope: entityConfig.readScope,
        })
        return await axios.get(
            entityConfig.baseUrl +
                filtersToParams(filters, baseHasParams(entityConfig.baseUrl)),
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        )
    }

    const fetchInsecure = async function (filters) {
        return await axios.get(
            entityConfig.baseUrl +
                filtersToParams(filters, baseHasParams(entityConfig.baseUrl)),
            {}
        )
    }

    const fetchAll = async function () {
        return await fetch([])
    }
    const fetchOne = async function (id) {
        const accessToken = await getAccessTokenSilently({
            audience: auto0Config.audience,
            scope: entityConfig.readScope,
        })

        return await axios.get(entityConfig.baseUrl + "/" + id, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        })
    }
    const update = async function (data) {
        const accessToken = await getAccessTokenSilently({
            audience: auto0Config.audience,
            scope: entityConfig.writeScope,
        })
        return await axios.put(entityConfig.baseUrl + "/" + data.id, data, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        })
    }
    const insert = async function (data) {
        const accessToken = await getAccessTokenSilently({
            audience: auto0Config.audience,
            scope: entityConfig.writeScope,
        })
        return await axios.post(entityConfig.baseUrl, data, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        })
    }

    const insertInsecure = async function (data) {
        return await axios.post(entityConfig.baseUrl, data, {})
    }
    const updateInsecure = async function (data) {
        return await axios.put(entityConfig.baseUrl + "/" + data.id, data, {})
    }
    const remove = async function (data) {
        const accessToken = await getAccessTokenSilently({
            audience: auto0Config.audience,
            scope: entityConfig.writeScope,
        })
        return await axios.delete(entityConfig.baseUrl + "/" + data.id, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        })
    }
    return {
        fetchAll,
        fetchInsecure,
        fetch,
        fetchOne,
        update,
        updateInsecure,
        insert,
        insertInsecure,
        remove,
    }
}

function addEvents(r) {
    if (r.events) {
        r.object[Symbol.for("events")] = r.events

        let lastMod = null
        for (const e of r.events) {
            if (["insert", "update"].includes(e.type)) {
                if (lastMod === null) {
                    lastMod = new Date(e.at)
                } else if (lastMod < new Date(e.at)) {
                    lastMod = new Date(e.at)
                }
            }
        }
        r.object[Symbol.for("lastMod")] = lastMod
    }
}

function noopDispatch(config, state) {
    const f = async () => {
        return true
    }
    return {
        insert: f,
        update: f,
        remove: f,
        fetch: async () => {
            return state[config.stateKey].data
        },
        clearSegment: f,
    }
}

export function useDispatch(appConfig, config, dispatch, state) {
    const apiCalls = useCalls(appConfig, config, state)
    const upsert = async (values) => {
        const r = state[config.stateKey].data.find((d) => d.id === values.id)

        try {
            if (r) {
                if (config.insecure) {
                    await apiCalls.updateInsecure(values)
                } else {
                    await apiCalls.update(values)
                }
            } else {
                if (config.insecure) {
                    await apiCalls.insertInsecure(values)
                } else {
                    await apiCalls.insert(values)
                }
            }

            values[Symbol.for("lastMod")] = new Date()
            dispatch({ type: config.type, action: "UPSERT", payload: values })
        } catch (error) {
            console.error(error)
            const event = new CustomEvent("infoMessage", {
                detail: {
                    type: "error",
                    message: `Issue updating ${config.name}. The data was not saved to the server.`,
                },
            })
            document.dispatchEvent(event)
            return false
        }
        return true
    }
    const remove = async (values) => {
        try {
            if (config.noCalls) {
                dispatch({
                    type: config.type,
                    action: "DELETE",
                    payload: values,
                })
                return true
            }
            const result = await apiCalls.remove(values)
            if (result.data.results) {
                //For non-true delete
                addEvents(result.data.results[0])
                dispatch({
                    type: config.type,
                    action: "UPSERT",
                    payload: result.data.results[0].object,
                })
            } else {
                dispatch({
                    type: config.type,
                    action: "DELETE",
                    payload: values,
                })
            }
        } catch (error) {
            console.error(error)
            const event = new CustomEvent("infoMessage", {
                detail: {
                    type: "error",
                    message: `Issue deleting ${config.name}. The data was not saved to the server.`,
                },
            })
            document.dispatchEvent(event)
        }
        return true
    }
    async function refresh(filters = []) {
        if (config.noCalls) {
            return true
        }
        try {
            const result = config.insecure
                ? await apiCalls.fetchInsecure(filters)
                : await apiCalls.fetch(filters)
            const data = []
            for (const r of result.data.results) {
                addEvents(r)
                data.push(r.object)
            }

            if (filters.length === 0) {
                dispatch({
                    type: config.type,
                    action: "INITIALIZE",
                    payload: { initialFetch: true, data: data, loading: false },
                })
            } else {
                dispatch({
                    type: config.type,
                    action: "INITIALIZE_SEGMENT",
                    payload: {
                        segmentField: config.partitionField,
                        data: data,
                        key: filters[0].value,
                    },
                })
            }

            return true
        } catch (error) {
            console.error(error)

            return false
        }
    }
    function clearSegment(key) {
        dispatch({
            type: config.type,
            action: "DELETE_SEGMENT",
            payload: {
                segmentField: config.partitionField,
                key: key,
            },
        })
    }
    function initialize(data) {
        dispatch({
            type: config.type,
            action: "INITIALIZE",
            payload: {
                initialFetch: true,
                fetchedKeys: {},
                data: data,
            },
        })
    }

    return { upsert, remove, refresh, clearSegment, initialize }
}

export function filterOne(items, filter) {
    const found = items.find(
        (element) => valueInKeyPath(filter.field, element) === filter.value
    )
    if (found) {
        return [found]
    } else {
        return []
    }
}

export function filterEq(items, filter) {
    const found = items.filter(
        (element) => valueInKeyPath(filter.field, element) == filter.value
    )
    return found
}

export function filterStrEq(items, filter) {
    const found = items.filter((element) => {
        const item = valueInKeyPath(filter.field, element)
        const value = filter.value
        if (typeof item !== "string") {
            return []
        }
        return item.toLowerCase() == value.toLowerCase()
    })
    return found
}
export function filterIn(items, filter) {
    const found = items.filter((element) =>
        filter.value.includes(valueInKeyPath(filter.field, element))
    )
    return found
}
export function filterLike(items, filter) {
    const found = items.filter((element) =>
        valueInKeyPath(filter.field, element).includes(filter.value)
    )
    return found
}
export function filteriLike(items, filter) {
    const found = items.filter((element) => {
        const item = valueInKeyPath(filter.field, element)
        const value = filter.value
        if (typeof item !== "string") {
            return []
        }
        return item.toLowerCase().includes(value.toLowerCase())
    })
    return found
}
export function filterOr(items, filter) {
    const subs = []
    for (const f of filter.value) {
        subs.push(applyFilters([...items], [f]))
    }

    return union(...subs)
}

export function filterAny(items, filter) {
    const filters = []
    for (const f of filter.value) {
        filters.push({
            type: "eq",
            field: filter.field,
            value: f,
        })
    }
    return filterOr(items, { value: filters })
}

export function filterObjectLike(items, filter) {
    const found = items.filter((element) => {
        return JSON.stringify(valueInKeyPath(filter.field, element))
            .toLocaleLowerCase()
            .includes(filter.value.toLocaleLowerCase())
    })
    return found
}

export function applyFilters(itmes, filters) {
    let i = [...itmes]
    for (const f of filters) {
        if (f.type === "or") {
            i = filterOr(f.additive ? itmes : i, f)
        }
        if (f.type === "one") {
            i = filterOne(f.additive ? itmes : i, f)
        }
        if (f.type === "eq") {
            i = filterEq(f.additive ? itmes : i, f)
        }
        if (f.type === "streq") {
            i = filterStrEq(f.additive ? itmes : i, f)
        }
        if (f.type === "like") {
            i = filterLike(f.additive ? itmes : i, f)
        }
        if (f.type === "ilike") {
            i = filteriLike(f.additive ? itmes : i, f)
        }
        if (f.type === "in") {
            i = filterIn(f.additive ? itmes : i, f)
        }
        if (f.type === "any") {
            i = filterAny(f.additive ? itmes : i, f)
        }
        if (f.type === "objectLike") {
            i = filterObjectLike(f.additive ? itmes : i, f)
        }

        if (f.type === "_sort" || f.type === "sort") {
            i = i.sort((el1, el2) => {
                const key = f.key ? f.key : f.field
                if (
                    valueInKeyPath(key, el1) === null ||
                    valueInKeyPath(key, el2) === null
                ) {
                    return 0
                }

                if (!f.direction || f.direction === "ASC") {
                    if (valueInKeyPath(key, el1) === undefined) {
                        return 0
                    }
                    const e1 = f.numeric
                        ? Number(valueInKeyPath(key, el1))
                        : valueInKeyPath(key, el1)
                              .toString()
                              .toLocaleLowerCase()
                    const e2 = f.numeric
                        ? Number(valueInKeyPath(key, el2))
                        : valueInKeyPath(key, el2)
                              .toString()
                              .toLocaleLowerCase()

                    if (e1 > e2) {
                        return 1
                    } else {
                        return -1
                    }
                } else {
                    if (valueInKeyPath(key, el1) === undefined) {
                        return 0
                    }

                    const e1 = f.numeric
                        ? Number(valueInKeyPath(key, el1))
                        : valueInKeyPath(key, el1)
                              .toString()
                              .toLocaleLowerCase()
                    const e2 = f.numeric
                        ? Number(valueInKeyPath(key, el2))
                        : valueInKeyPath(key, el2)
                              .toString()
                              .toLocaleLowerCase()
                    if (e1 < e2) {
                        return 1
                    } else {
                        return -1
                    }
                }
            })
        }

        if (f.type === "listSort") {
            i = i.sort((el1, el2) => {
                const v1 = valueInKeyPath(f.field, el1)
                const v2 = valueInKeyPath(f.field, el2)
                if (f.value.indexOf(v1) === f.value.indexOf(v2)) {
                    return 0
                }
                if (f.value.indexOf(v1) < f.value.indexOf(v2)) {
                    return -1
                } else {
                    return 1
                }
            })
        }
    }

    return i
}

const __loading = []

function doInitialLoad(state, dispatch, config) {
    if (!state.state[config.stateKey].initialFetch) {
        /**
         * React magic
         * if we are loading just return an empty result
         * once we are done loading the dispatch update caused by
         * refresh will trigger rerendering of componts that will
         * call filter again and get a dataset
         */
        if (__loading[config.stateKey]) {
            return true
        } else {
            __loading[config.stateKey] = true
            dispatch.refresh().then(() => {
                __loading[config.stateKey] = false
            })

            return true
        }
    }
    return false
}

const __keyLoading = []

function doKeyedLoad(filters, state, dispatch, config) {
    const query = filters.find((f) => f.field === config.partitionField)
    if (!query) {
        throw new Error(
            "configs with a partitionField must always be filterd by that field"
        )
    }
    const key = query.value
    if (!state.state[config.stateKey].fetchedKeys[key]) {
        if (__keyLoading[key]) {
            return true
        } else {
            __keyLoading[key] = true
            dispatch
                .refresh([{ field: config.partitionField, value: key }])
                .then(() => {
                    __keyLoading[key] = false
                })

            return true
        }
    }
    return false
}

export function useEntity(appConfig, config, useState) {
    const state = useState()
    const dispatch = useDispatch(appConfig, config, state.dispatch, state.state)

    function filter(filters) {
        if (config.partitionField) {
            if (doKeyedLoad(filters, state, dispatch, config)) {
                //For now try anyway. This solved a bug with non-keyed public shares
                // That also depends on keys private methods
                //return []
            }
        } else {
            if (doInitialLoad(state, dispatch, config)) {
                return []
            }
        }
        let c = state.state[config.stateKey].data
        if (filters.find((e) => e.type === "_sort") === undefined) {
            filters.push({ type: "_sort", key: config.defaultSortKey })
        }
        return applyFilters(c, filters)
    }
    return {
        dispatch,
        filter,
        meta: { initialLoad: state.state[config.stateKey].initialFetch },
    }
}

export function sortHelper(filter, key) {
    const sort = filter.find((e) => e.type === "_sort")
    if (sort === undefined) {
        filter.push({ type: "_sort", key: key })
    } else {
        if (sort.key === key) {
            if (!sort.direction || sort.direction === "ASC") {
                sort.direction = "DSC"
            } else {
                sort.direction = "ASC"
            }
        } else {
            sort.direction = "ASC"
            sort.key = key
        }
    }
    return filter
}

export function groupBy(results, groupKeyPath) {
    if (!results) {
        return {}
    }
    const grouped = {}
    for (const row in results) {
        const gvalue = valueInKeyPath(groupKeyPath, row)
        if (!grouped[gvalue]) {
            grouped[gvalue] = []
        }
        grouped[gvalue].push(row)
    }
    return grouped
}
