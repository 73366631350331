import React, { createContext, useReducer, useContext } from "react"
import { cloneDeep } from "lodash"

export function setupState(initialState, reducers) {
    const combineReducers =
        (reducers) =>
        (state = initialState, action) => {
            for (let i = 0; i < reducers.length; i++) {
                state = reducers[i](state, action)
            }
            window.sessionStorage.setItem("state", JSON.stringify(state))
            return state
        }

    let store = null

    if (window.sessionStorage.getItem("state")) {
        initialState = JSON.parse(window.sessionStorage.getItem("state"))
    }
    store = createContext(initialState)

    const { Provider } = store

    const StateProvider = ({ children }) => {
        const [state, dispatch] = useReducer(
            combineReducers(reducers),
            initialState
        )
        return React.createElement(
            Provider,
            { value: { state, dispatch } },
            children
        )
        //return <Provider value={{ state, dispatch }}>{children}</Provider>
    }

    const useGlobalState = () => useContext(store)

    return { store, StateProvider, useGlobalState }
}

export function defaultStateHandle(key, fakeObj, skipFetch) {
    let fo = fakeObj
    if (!Array.isArray(fakeObj)) {
        fo = [fo]
    }
    const initialState = {
        initialFetch: skipFetch ? true : false,
        fetchedKeys: {},
        data: fo,
    }

    function reducer(state, action) {
        switch (action.type) {
            case key:
                switch (action.action) {
                    case "UPSERT":
                        const items = cloneDeep(state[key.toLowerCase()].data)
                        const itemKey = items.findIndex(
                            (x) => x.id === action.payload.id
                        )
                        delete action.payload.__new
                        if (itemKey === -1) {
                            items.push(action.payload)
                        } else {
                            items[itemKey] = action.payload
                        }

                        return {
                            ...state,
                            [key.toLowerCase()]: {
                                ...state[key.toLowerCase()],
                                data: items,
                            },
                        }
                    case "DELETE_SEGMENT":
                        let dsitems = [...state[key.toLowerCase()].data]
                        let dsFetchedKeys = {
                            ...state[key.toLowerCase()].fetchedKeys,
                        }
                        const dsSegmentField = action.payload.segmentField
                        const sdk = cloneDeep(action.payload.key)
                        //Clean out everything out from the segment
                        dsitems = dsitems.filter(
                            (item) => !(item[dsSegmentField] === sdk)
                        )
                        dsFetchedKeys[sdk] = false
                        return {
                            ...state,
                            [key.toLowerCase()]: {
                                initialFetch: true,
                                data: dsitems,
                                fetchedKeys: dsFetchedKeys,
                                loading: false,
                            },
                        }

                    case "INITIALIZE_SEGMENT":
                        let isitems = [...state[key.toLowerCase()].data]
                        let fetchedKeys = {
                            ...state[key.toLowerCase()].fetchedKeys,
                        }

                        const segmentField = action.payload.segmentField
                        const k = action.payload.key
                        //Clean out everything out from the segment
                        isitems = isitems.filter(
                            (item) => !(item[segmentField] === k)
                        )

                        //Put evertthing back in from the segment
                        isitems = isitems.concat(action.payload.data)
                        fetchedKeys[k] = true
                        return {
                            ...state,
                            [key.toLowerCase()]: {
                                initialFetch: true,
                                data: isitems,
                                fetchedKeys: fetchedKeys,
                                loading: false,
                            },
                        }

                    case "DELETE":
                        const ditems = cloneDeep(state[key.toLowerCase()].data)
                        const dkey = ditems.findIndex(
                            (x) => x.id === action.payload.id
                        )
                        if (dkey === -1) {
                            return state
                        } else {
                            ditems.splice(dkey, 1)
                        }
                        return {
                            ...state,
                            [key.toLowerCase()]: {
                                ...state[key.toLowerCase()],
                                data: ditems,
                            },
                        }
                    case "INITIALIZE":
                        return { ...state, [key.toLowerCase()]: action.payload }
                    default:
                        return state
                }
            default:
                return state
        }
    }

    return { initialState, reducer }
}
