import { Stack } from "@fluentui/react"
import { TextField } from "@fluentui/react/lib/TextField"
import React, { useState } from "react"

export default function Inches(props) {
    const value = props.value
    const ft = Math.floor(value / 12)

    const ins = value - ft * 12

    function onChange(ft, ins) {
        props.onChange(ft * 12 + ins)
    }

    const [feet, setFeet] = useState(ft ? ft : 0)
    const [inches, setInches] = useState(ins ? ins : 0)

    return (
        <Stack horizontal>
            <TextField
                label={props.label + " feet"}
                name={props.name}
                type="number"
                value={feet}
                onChange={(e) => {
                    onChange(Number(e.target.value), inches)
                    setFeet(Number(e.target.value))
                }}
                errorMessage={props.errorMessage ? props.errorMessage : false}
                onGetErrorMessage={props.onGetErrorMessage}
                suffix={"feet"}
                readOnly={props.readOnly}
            />
            <TextField
                label={props.label + " inches"}
                name={props.name}
                type="number"
                value={inches}
                onChange={(e) => {
                    onChange(feet, Number(e.target.value))
                    setInches(Number(e.target.value))
                }}
                errorMessage={props.errorMessage ? props.errorMessage : false}
                onGetErrorMessage={props.onGetErrorMessage}
                suffix={"inches"}
                readOnly={props.readOnly}
            />
        </Stack>
    )
}
