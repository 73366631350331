import React from "react"

import { save as saveValidator } from "@sevenpoint/schema"
import config from "../config"
import { EntityList, EntityForm } from "./Entities"
import { useEntityFromConfig } from "../hooks/Entity"
import { useRoomsConfig } from "./Rooms"
import { Link } from "@fluentui/react"
import { useHistory } from "react-router-dom"
import { useGlobalState } from "../state"
import { usePublicProjectsConfig } from "./Projects"

const date = new Date()

export function useSaveConfig() {
    const history = useHistory()
    const useRooms = useEntityFromConfig(useRoomsConfig())
    const useProjects = useEntityFromConfig(usePublicProjectsConfig())
    const state = useGlobalState()

    const project = useProjects.filter([])[0]
    return {
        baseUrl: config.api.baseUrl + "/saves",
        type: "SAVES",
        stateKey: "saves",
        name: "Saves",
        nameSingle: "Save",
        readScope: "read:items",
        writeScope: "write:items",
        defaultSortKey: "createdAt",
        allowAdd: false,

        listColumns: [
            {
                key: "contact.email",
                title: "Email",
            },
            {
                key: "contact.name",
                title: "Name",
            },
            {
                key: "contact.phone",
                title: "Phone Number",
            },

            {
                key: "createdAt",
                title: "Created",
                formatter: (value) => {
                    return new Date(value).toLocaleString()
                },
            },
            {
                key: "status",
                title: "Status",
                formatter: (value) => {
                    if (value === "new") {
                        return "New"
                    }
                    return "Fulfilled "
                },
            },
            {
                key: "data",
                title: "View",
                exportFormatter: (data) => {
                    return ""
                },
                formatter: (data) => {
                    return (
                        <Link
                            underline
                            onClick={(e) => {
                                console.log(data)
                                state.dispatch({
                                    type: "ROOMS",
                                    action: "INITIALIZE",
                                    payload: {
                                        initialFetch: true,
                                        fetchedKeys: {},
                                        data: data.rooms,
                                    },
                                })

                                if (data.project) {
                                    state.dispatch({
                                        type: "PROJECTS",
                                        action: "INITIALIZE",
                                        payload: {
                                            initialFetch: true,
                                            fetchedKeys: {},
                                            data: [data.project],
                                        },
                                    })
                                }
                                history.push("/")
                            }}
                        >
                            view
                        </Link>
                    )
                },
            },
        ],
        formFields: [
            {
                label: "Project",
                key: "projectId",
                type: "entity",
                descrKeyPath: "name",
                valueKeyPath: "id",
                options: useProjects.filter([]),
                disabledOnUpdate: true,
            },
            {
                label: "Name",
                key: "contact.name",
                type: "text",
            },
            {
                label: "Email",
                key: "contact.email",
                type: "email",
            },
            {
                label: "Status",
                key: "status",
                type: "dropdown",
                options: [
                    { key: "new", text: "New" },
                    {
                        key:
                            "closed_" +
                            (date.getMonth() + 1).toString() +
                            "_" +
                            date.getFullYear(),
                        text: "Fulfilled",
                    },
                ],
            },

            {
                label: "Address",
                key: "contact.address",
                type: "text",
                option: "multiline",
            },
            {
                label: "Country",
                key: "contact.country",
                type: "country",
            },
            {
                label: "City",
                key: "contact.city",
                type: "text",
            },
            {
                label: "State/Province",
                key: "contact.state",
                type: "state",
                coupledValue: "contact.country",
            },

            {
                label: "Zip/Postal",
                key: "contact.postal",
                type: "text",
            },
            {
                label: "Phone",
                key: "contact.phone",
                type: "tel",
                coupledValue: "contact.country",
            },
        ],
        emptyFormValue: {
            projectId: project?.id,

            contact: project ? project.contact : {},
        },
        validator: saveValidator,
        Form: SaveForm,
    }
}

export function usePublicSaveConfig() {
    const publicSaveConfig = { ...useSaveConfig() }
    publicSaveConfig.baseUrl = config.api.baseUrl + "/public/saves"
    publicSaveConfig.insecure = true
    publicSaveConfig.formFields = [...publicSaveConfig.formFields]
    const i = publicSaveConfig.formFields.findIndex((e) => e.key === "status")
    publicSaveConfig.formFields.splice(i, 1)
    return publicSaveConfig
}

export function SavesList(props) {
    const saveConfig = useSaveConfig()
    return <EntityList config={saveConfig} />
}

export function SaveForm(props) {
    const saveConfig = useSaveConfig()

    return <EntityForm {...props} config={saveConfig} />
}

export function PublicSaveForm(props) {
    const publicSaveConfig = usePublicSaveConfig()
    const useRooms = useEntityFromConfig(useRoomsConfig())
    const project = props.project
    const onUpsert = (entity, values) => {
        values.data = { rooms: useRooms.filter([]), project: project }
        console.log(values)
        entity.dispatch.upsert(values)
        props.onSubmit(values)
    }
    return (
        <EntityForm {...props} config={publicSaveConfig} onUpsert={onUpsert} />
    )
}
