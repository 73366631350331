import React from "react"
import {
    Modal as FModal,
    ResponsiveMode,
    Stack,
    useTheme,
} from "@fluentui/react"

import { Icon } from "@fluentui/react/lib/Icon"

function useStyles() {
    const theme = useTheme()
    const headerStyles = {
        root: {
            backgroundColor: theme.palette.themeDark,
            color: theme.palette.white,
            height: 30,
            alignItems: "center",
            paddingLeft: 6,
        },
    }

    const iconStyles = {
        root: {
            width: 30,
            cursor: "pointer",
            alignItems: "center",
            textAlign: "center",
        },
    }

    const modalStyles = {}

    const contentStyles = {
        root: {
            padding: 6,
        },
    }
    return [headerStyles, iconStyles, modalStyles, contentStyles]
}

export default function Modal(props) {
    const [headerStyles, iconStyles, modalStyles, contentStyles] = useStyles()
    return (
        <FModal
            styles={modalStyles}
            isOpen={props.open ? props.open : true}
            onDismiss={() => {
                props.onClose()
            }}
            isBlocking={true}
            isDarkOverlay={true}
            responsiveMode={ResponsiveMode.large}
        >
            <Stack>
                <Stack styles={headerStyles} horizontal>
                    {props.title && (
                        <Stack.Item grow={1}>
                            <span>{props.title}</span>
                        </Stack.Item>
                    )}
                    <Stack.Item>
                        <Icon
                            onClick={() => {
                                props.onClose()
                            }}
                            styles={iconStyles}
                            iconName="ChromeClose"
                        />
                    </Stack.Item>
                </Stack>
                <Stack.Item styles={contentStyles}>{props.children}</Stack.Item>
            </Stack>
        </FModal>
    )
}
