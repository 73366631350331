import React from "react"

import { item as itemValidator } from "@sevenpoint/schema"
import config from "../config"

export const itemsPublicConfig = {
    baseUrl: config.api.baseUrl + "/public/items",
    type: "ITEMS",
    stateKey: "items",
    name: "Items",
    nameSingle: "Item",
    readScope: "read:items",
    writeScope: "write:items",
    defaultSortKey: "name",
    allowDelete: false,
    validator: itemValidator,
    insecure: true,
}
