export function RotateLeft(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.5 15a.75.75 0 0 1 0-1.5h.5c2.485 0 4.5-1.567 4.5-3.5 0-1.874-1.895-3.405-4.275-3.496L15 6.5H8.061l1.72 1.72a.75.75 0 0 1-.977 1.133L8.72 9.28l-3-3a.75.75 0 0 1 0-1.06l3-3 .084-.073A.75.75 0 0 1 9.78 3.28L8.062 5H15c3.314 0 6 2.239 6 5s-2.686 5-6 5h-.5ZM3.5 10.367a.5.5 0 0 0-.5.5V20a1 1 0 0 0 1 1h16.138a.5.5 0 0 0 .251-.933L3.751 10.434a.5.5 0 0 0-.251-.067Z"
                fill="#00000"
            />
        </svg>
    )
}

export function RotateRight(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.5 15a.75.75 0 0 0 0-1.5H9c-2.485 0-4.5-1.567-4.5-3.5 0-1.874 1.894-3.405 4.275-3.496L9 6.5h6.939l-1.72 1.72a.75.75 0 0 0 .977 1.133l.084-.073 3-3a.75.75 0 0 0 0-1.06l-3-3-.084-.073a.75.75 0 0 0-.976 1.133L15.938 5H9c-3.314 0-6 2.239-6 5s2.686 5 6 5h.5ZM20.5 10.367a.5.5 0 0 1 .5.5V20a1 1 0 0 1-1 1H3.862a.5.5 0 0 1-.251-.933l16.638-9.633a.5.5 0 0 1 .251-.067Z"
                fill="#00000"
            />
        </svg>
    )
}

export function Center(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.75 3a.75.75 0 0 1 .75.75v16.5a.75.75 0 0 1-1.5 0V3.75A.75.75 0 0 1 3.75 3Zm16.5 0a.75.75 0 0 1 .75.75v16.5a.75.75 0 0 1-1.5 0V3.75a.75.75 0 0 1 .75-.75Zm-10 2A2.25 2.25 0 0 0 8 7.25v9.5A2.25 2.25 0 0 0 10.25 19h3.5A2.25 2.25 0 0 0 16 16.75v-9.5A2.25 2.25 0 0 0 13.75 5h-3.5Z"
                fill="#00000"
            />
        </svg>
    )
}
