import React from "react"

import { item as itemValidator } from "@sevenpoint/schema"
import config from "../config"
import { EntityList, EntityForm } from "./Entities"
import { useEntityFromConfig } from "../hooks/Entity"
import { materialsConfig } from "./Materials"
import { v4 as uuidv4 } from "uuid"
import { skusConfig } from "./Skus"

export function useItemsConfig() {
    const useSkus = useEntityFromConfig(skusConfig)
    return {
        baseUrl: config.api.baseUrl + "/items",
        type: "ITEMS",
        stateKey: "items",
        name: "Items",
        nameSingle: "Item",
        readScope: "read:items",
        writeScope: "write:items",
        defaultSortKey: "name",
        allowDelete: true,
        listColumns: [
            {
                key: "name",
                title: "Name",
                minWidth: 400,
                maxWidth: 400,
            },
            {
                key: "serialBase",
                title: "Serial Base",
            },
            {
                key: "isBase",
                title: "Is Base",
                formatter: (i) => {
                    return i ? "true" : "false"
                },
            },
            {
                key: "width",
                title: "Width",
            },
            {
                key: "subitems",
                title: "Subitems",
                formatter: (i) => {
                    if (i) {
                        return i.length
                    }
                    return 0
                },
            },
            {
                key: "id",
                title: "Matching Skus",
                additionalKeys: ["serialBase"],

                formatter: (i, values) => {
                    const skus = values[0].split(",")
                    let count = 0
                    for (const sku of skus) {
                        const r = useSkus.filter([
                            {
                                field: "serialBase",
                                type: "eq",
                                value: sku.trim(),
                            },
                        ])
                        count = count + r.length
                    }
                    return count
                },
            },
        ],

        emptyFormValue: { materialIds: [], subitems: [] },
        validator: itemValidator,
        Form: ItemForm,
    }
}

export function ItemList(props) {
    const itemsConfig = useItemsConfig()
    return <EntityList config={itemsConfig} />
}

export function ItemForm(props) {
    const itemsConfig = useItemsConfig()
    const useMaterials = useEntityFromConfig(materialsConfig)
    const useItems = useEntityFromConfig(itemsConfig)
    itemsConfig.formFields = [
        {
            label: "Name",
            key: "name",
            type: "text",
        },
        {
            label: "Image",
            key: "imageId",
            type: "image",
            onlyOne: true,
            allowDelete: true,
            anyFiles: false,
        },
        {
            label: "GLTF Model",
            key: "modelId",
            type: "image",
            onlyOne: true,
            allowDelete: true,
            gltfModel: true,
            anyFiles: true,
        },
        {
            label: "Base",
            key: "isBase",
            type: "toggle",
            onText: "Is Base",
            offText: "Is Not Base",
        },
        {
            label: "Serial Base",
            key: "serialBase",
            type: "text",
        },
        {
            label: "Width (inches)",
            key: "width",
            type: "text",
            step: 0.1,
        },
        {
            label: "Material Type ",
            key: "materialType",
            type: "dropdown",
            options: [
                { key: "wood", text: "Wood" },
                { key: "metal", text: "Metal" },
            ],
        },
        {
            label: "Embeddable Items ",
            addLabel: "Add Item",
            key: "subitems",
            type: "array",
            emptyFormValue: () => {
                return { key: uuidv4() }
            },
            fields: [
                {
                    label: "Items",
                    key: "id",
                    type: "entity",
                    descrKeyPath: "name",
                    valueKeyPath: "id",
                    options: useItems.filter([]),
                },
                {
                    label: "Type",
                    key: "type",
                    type: "dropdown",
                    options: [
                        { key: "cabnet", text: "Cabnet" },
                        { key: "shelf", text: "Shelf" },
                        { key: "header", text: "Header" },
                        { key: "display", text: "Display Box" },
                    ],
                },
                {
                    label: "Allowed Y positions (, seprated)",
                    key: "ypositions",
                    type: "text",
                },
            ],
        },
    ]

    return <EntityForm {...props} config={itemsConfig} />
}
