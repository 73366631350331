import React from "react"

import { sku as skuValidator } from "@sevenpoint/schema"
import config from "../config"

export const skusConfigPublic = {
    baseUrl: config.api.baseUrl + "/public/skus",
    type: "SKUS",
    stateKey: "skus",
    name: "Skus",
    nameSingle: "Sku",
    readScope: "read:items",
    writeScope: "write:items",
    defaultSortKey: "name",
    allowDelete: false,
    validator: skuValidator,
    insecure: true,
}
