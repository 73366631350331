import Panel from "../layouts/Panel"
import { RoomList } from "./Rooms"

export default function RoomsPanel(props) {
    return (
        <Panel
            onClose={props.onClose}
            onSave={props.onSave}
            lightDismiss={false}
            isOpen={true}
            hideOverlay={false}
            title={"Rooms"}
            large
            hideButtons
        >
            <RoomList
                currentRoom={props.currentRoom}
                onSelected={(id) => {
                    props.onSelected(id)
                    props.onClose()
                }}
                disablePrint
            />
        </Panel>
    )
}
