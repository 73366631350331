import * as yup from "yup"
import YupPassword from "yup-password"

YupPassword(yup)

const uuid =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i

function createContact(noName = false) {
    return yup.object().shape({
        name: noName
            ? yup.string().ensure()
            : yup.string().required("name is required"),
        address: yup.string().ensure(),
        postal: yup.string().ensure(),
        city: yup.string().ensure(),
        state: yup.string().ensure(),
        email: yup.string().email().ensure(),
        phone: yup
            .string()
            .ensure()
            .matches(/^((?!_).)*$/, {
                message: "phone must be complete and not contain _",
                excludeEmptyString: true,
            }),
        mobile: yup
            .string()
            .ensure()
            .matches(/^((?!_).)*$/, {
                message: "phone must be complete and not contain _",
                excludeEmptyString: true,
            }),
        country: yup.string().ensure(),
    })
}
const contact = createContact()

const contactEmailRequired = createContact()
contactEmailRequired.email = yup.string().email().required()

const contactNoRequired = createContact(true)

yup.addMethod(yup.number, "nullEmptyString", function () {
    return this.transform((val) => (val === Number(val) ? val : null))
})

export const user = yup.object().shape({
    id: yup.string().matches(uuid, "id must be a valid uuid").required(),
    password: yup.string().nullable().password(), ///Only every something creation or password change
    contact: contactEmailRequired,
    enabled: yup.number().min(0).max(1),
    prefs: yup
        .object({
            darkMode: yup.string().default("auto").required(),
        })
        .nullable(),
})

export const image = yup.object().shape({
    id: yup.string().matches(uuid, "id must be a valid uuid").required(),
    name: yup.string().required("name is required"),
    location: yup.string().required("location is required"),
    meta: yup.object().nullable(),
})

export const info = yup.object().shape({
    userId: yup.string().matches(uuid, "id must be a valid uuid").required(),
    departmentId: yup
        .string()
        .matches(uuid, "id must be a valid uuid")
        .required(),
})

export const item = yup.object().shape({
    id: yup.string().matches(uuid, "id must be a valid uuid").required(),
    name: yup
        .string()

        .required(),
    modelId: yup
        .string()
        .matches(uuid, "modelId must be a valid uuid")
        .required(),
    imageId: yup
        .string()
        .matches(uuid, "imageId must be a valid uuid")
        .nullable(),
    subitmes: yup.array().of(yup.mixed()).ensure(),
    isBase: yup.number().oneOf([0, 1]),
    serialBase: yup.string().ensure(),
    width: yup.number().positive(),
    materialType: yup.string().oneOf(["wood", "metal"]).required(),
})

export const placedItems = yup.object().shape({
    id: yup.string().matches(uuid, "id must be a valid uuid").required(),
    itemId: yup
        .string()
        .matches(uuid, "item id must be a valid uuid")
        .required(),
    materialId: yup
        .string()
        .matches(uuid, "item id must be a valid uuid")
        .nullable(),
    subitems: yup.array().of(yup.mixed()).ensure(),
    type: yup.string().ensure(),
    x: yup.number().required(),
    y: yup.number().required(),
    z: yup.number().required(),
})

export const room = yup.object().shape({
    id: yup.string().matches(uuid, "id must be a valid uuid").required(),
    name: yup.string().required(),
    type: yup.string().oneOf(["wall"]),
    width: yup.number().min(40, 'wall must be at least 40" wide').required(),
    depth: yup.number().nullable(),
    height: yup.number().nullable(),
    items: yup.array().of(placedItems),
    metalMaterialId: yup
        .string()
        .matches(uuid, "frameMateralId must be a valid uuid")
        .required(),
    woodMaterialId: yup
        .string()
        .matches(uuid, "frameMateralId must be a valid uuid")
        .required(),
})

export const material = yup.object().shape({
    id: yup.string().matches(uuid, "id must be a valid uuid").required(),
    name: yup.string().required(),
    imageId: yup
        .string()
        .matches(uuid, "modelId must be a valid uuid")
        .nullable(),
    serialBase: yup.string().ensure(),
    color: yup.string().ensure(),
    type: yup.string().oneOf(["wood", "metal"]).required(),
})

export const sku = yup.object().shape({
    id: yup.string().matches(uuid, "id must be a valid uuid").required(),
    name: yup.string().required(),

    serialBase: yup.string().ensure(),
    price: yup.number().positive(),
})

export const save = yup.object().shape({
    id: yup.string().matches(uuid, "id must be a valid uuid").required(),
    contact: contactEmailRequired,
    data: yup.object(),
    status: yup.string(),
    projectId: yup.string().matches(uuid, "id must be a valid uuid").required(),
})

export const project = yup.object().shape({
    id: yup.string().matches(uuid, "id must be a valid uuid").required(),
    contact: contactEmailRequired,
    phase: yup.string(),
    type: yup.string(),
    name: yup.string(),
})
