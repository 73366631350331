import React, { useState, useEffect } from "react"
import { useGlobalState } from "../state"
import List from "../layouts/List"
import Form from "../layouts/Form"
import { v4 as uuidv4 } from "uuid"

import config from "../config"
import { sortHelper } from "@sevenpoint/ffsds/src/fe/entity"
import { cloneDeep } from "lodash"
import { useEntityFromConfig } from "../hooks/Entity"
import { CSVDownload } from "react-csv"
import Modal from "../layouts/Modal"
import FileImporter from "./FileImporter"

function Export(props) {
    const rows = props.fluentItems
    const rawRows = props.rows

    const columns = props.columns
    const headers = columns
        .filter((c) => c.exportable !== false)
        .map((c) => c.title)

    const eRows = []

    function esc(str) {
        if (str == null) {
            return null
        }
        return str.replace(/"/g, '""')
    }
    console.log(rows)
    for (const i in rows) {
        eRows.push(
            columns
                .filter((c) => c.exportable !== false)
                .map((c) =>
                    c.exportFormatter
                        ? esc(c.exportFormatter(rawRows[i][c.key], rawRows[i]))
                        : esc(rows[i][c.key])
                )
        )
    }

    return <CSVDownload data={eRows} headers={headers} />
}

export function EntityList(props) {
    const entityConfig = props.config
    const [DoExport, setDoExport] = useState(false)

    const entity = useEntityFromConfig(entityConfig)
    const [filter, setFilter] = useState(
        props.config.filter ? props.config.filter : []
    )

    useEffect(() => {
        if (props.config.filter) {
            setFilter(props.config.filter)
        }
    }, [props.config.filter])

    const fullRows = entityConfig.fullSetFilter
        ? entity.filter(entityConfig.fullSetFilter)
        : null

    const entities = entity.filter(filter)
    const listConfig = {
        title: entityConfig.name,
        printTitle: entityConfig.printTitle,
        printFooter: entityConfig.printFooter,

        disablePrint: entityConfig.disablePrint,
        columns: entityConfig.listColumns,
        Form: entityConfig.Form,
        addButtonLabel: "Add " + entityConfig.nameSingle,
        updateButtonLabel: entityConfig.readOnly
            ? entityConfig.nameSingle
            : "Update " + entityConfig.nameSingle,
        allowAdd:
            entityConfig.allowAdd !== undefined ? entityConfig.allowAdd : true,
        rows: entities,
        multiSelection: props.multiSelection,
        importKey: entityConfig.importKey,
        fullRows: fullRows,
        hideButtons: entityConfig.insecure
            ? entityConfig.insecure
            : props.hideButtons,
        onUpsert: entity.dispatch.upsert,
        onRefresh: entityConfig.partitionField
            ? async () => {
                  return entity.dispatch.refresh([
                      {
                          field: entityConfig.partitionField,
                          type: "eq",
                          value: entityConfig.partitionKey,
                      },
                  ])
              }
            : entity.dispatch.refresh,

        onDelete: entityConfig.allowDelete ? entity.dispatch.remove : null,
        onSort: entityConfig.disableSort
            ? null
            : (key) => {
                  setFilter(sortHelper(cloneDeep(filter), key))
              },
        isLoading: !entity.meta.initialLoad,
        additionalButtons: props.config.additionalButtons,
        subMenus: props.config.subMenus,

        //Used for "values" on the form
        fetchForUpdate: (id) => {
            if (entityConfig.partitionField) {
                return entity.filter([
                    {
                        field: entityConfig.partitionField,
                        type: "eq",
                        value: entityConfig.partitionKey,
                    },
                    { field: "id", type: "one", value: id },
                ])[0]
            } else {
                return entity.filter([
                    { field: "id", type: "one", value: id },
                ])[0]
            }
        },
        onSearch: props.config.onSearch,
        readOnly: entityConfig.readOnly,
        PrintCover: props.config.PrintCover,
        onRowClick: props.onRowClick,
        onSelectionChanged: props.onSelectionChanged,
        onNew: props.onNew,
        onEdit: props.onEdit,
        searchStack: props.searchStack,
        doNotHidePrint: props.doNotHidePrint,
        styles: props.styles,

        onImport: entityConfig.importKey
            ? () => {
                  setDoExport(
                      <Modal
                          onClose={() => {
                              setDoExport(null)
                          }}
                      >
                          <FileImporter
                              entity={entity}
                              importKey={entityConfig.importKey}
                              importMaping={entityConfig.importMaping}
                          />
                      </Modal>
                  )
              }
            : null,
        onExport: (rows, fluentItems) => {
            setDoExport(
                <Export
                    rows={rows}
                    columns={entityConfig.listColumns}
                    fluentItems={fluentItems}
                />
            )
            setTimeout(() => setDoExport(false), 2000)
        },
    }
    return (
        <>
            {DoExport && <div>{DoExport}</div>}
            <List {...listConfig} />
        </>
    )
}

export function EntityForm(props) {
    const entityConfig = props.config
    const entity = useEntityFromConfig(entityConfig)

    const values = props.values
        ? props.values
        : entityConfig.emptyFormValue instanceof Function
        ? entityConfig.emptyFormValue()
        : { ...entityConfig.emptyFormValue }

    if (!values.id) {
        if (props.ensureId) {
            values.id = props.ensureId
        } else {
            values.id = uuidv4()
        }
    }

    const confg = {
        title: entityConfig.name,
        fields: entityConfig.formFields,
        values: values,
        onSubmit: props.onUpsert
            ? (values) => props.onUpsert(entity, values)
            : entity.dispatch.upsert,
        submitRef: props.submitRef,
        filter: [...(entityConfig.filter ? entityConfig.filter : [])],
        entity: entity,
        validate: (v) => {
            const r = entityConfig.validator.validate(v, { abortEarly: false })
            if (props.postValidate) {
                props.postValidate(v)
            }
            return r
        },
        validateAt: (key, v) => {
            const r = entityConfig.validator.validateAt(key, v, {
                abortEarly: true,
            })
            if (props.postValidate) {
                props.postValidate(v)
            }
            return r
        },
        showEvents: entityConfig.showEventsOnForm,

        //For modal
        showButtons: props.showButtons,
        onCancel: props.onCancel,
        readOnly: props.readOnly,
        setValuesRef: props.setValuesRef,
        valuesRef: props.valuesRef,
    }
    if (props.additionalFilter) {
        confg.filter = confg.filter.concat(props.additionalFilter)
    }

    return <Form {...confg} />
}
