import React from "react"

import { info as infosValidator } from "@sevenpoint/schema"
import config from "../config"

export const infoConfig = {
    baseUrl: config.api.baseUrl + "/infos",
    type: "INFOS",
    stateKey: "infos",
    name: "Infos",
    nameSingle: "Info",
    readScope: "read:users",
    defaultSortKey: "clientId",

    validator: infosValidator,
}
