import React, { useState, useEffect, useRef, useMemo } from "react"
import { StateProvider } from "./state/index"
import "./css/fluentGlobal.css"
import { ThemeProvider } from "@fluentui/react/lib/Theme"
import { mergeStyles, getTheme, AnimationClassNames } from "@fluentui/react"

import { initializeIcons } from "@fluentui/font-icons-mdl2"
import AuthMain from "./components/AuthMain"
import { BrowserRouter, Route, Switch } from "react-router-dom"
import PublicMain from "./components/PublicMain"

initializeIcons()

function AppWithTheme() {
    const myTheme = {
        palette: {
            themePrimary: "#6e6e6e",
            themeLighterAlt: "#f9f9f9",
            themeLighter: "#e8e8e8",
            themeLight: "#d3d3d3",
            themeTertiary: "#a8a8a8",
            themeSecondary: "#7f7f7f",
            themeDarkAlt: "#636363",
            themeDark: "#535353",
            themeDarker: "#3d3d3d",
            neutralLighterAlt: "#faf9f8",
            neutralLighter: "#f3f2f1",
            neutralLight: "#edebe9",
            neutralQuaternaryAlt: "#e1dfdd",
            neutralQuaternary: "#d0d0d0",
            neutralTertiaryAlt: "#c8c6c4",
            neutralTertiary: "#a19f9d",
            neutralSecondary: "#605e5c",
            neutralPrimaryAlt: "#3b3a39",
            neutralPrimary: "#323130",
            neutralDark: "#201f1e",
            black: "#000000",
            white: "#ffffff",
        },
        defaultFontStyle: {
            fontFamily: "regular",
            fontSize: "11pt",
        },
    }
    return (
        <ThemeProvider
            theme={myTheme}
            applyTo="body"
            style={{ height: "100%", width: "100%" }}
        >
            <BrowserRouter>
                <Switch>
                    <Route path="/admin">
                        <AuthMain />
                    </Route>
                    <Route>
                        <PublicMain />
                    </Route>
                </Switch>
            </BrowserRouter>
        </ThemeProvider>
    )
}

function App() {
    return (
        <StateProvider>
            <AppWithTheme />
        </StateProvider>
    )
}

export default App
