import React, { useEffect, useState } from "react"
import { useLocation, useHistory } from "react-router-dom"

import { Nav as FNav } from "@fluentui/react/lib/Nav"

import { useTheme } from "@fluentui/react"
import { useEntityFromConfig } from "../hooks/Entity"
import { infoConfig } from "./Infos"

const navLinks = [
    {
        links: [
            {
                name: "Users",
                link: "/admin/users",
                key: "/admin/users",
                icon: "Contact",
            },
            {
                name: "Items",
                link: "/admin/items",
                key: "/admin/items",
                icon: "Shapes",
            },
            {
                name: "Materials",
                link: "/admin/materials",
                key: "/admin/materials",
                icon: "Shapes",
            },
            {
                name: "Skus",
                link: "/admin/skus",
                key: "/admin/skus",
                icon: "WorkItemBar",
            },
            {
                name: "Saves",
                link: "/admin/saves",
                key: "/admin/saves",
                icon: "Save",
            },
            {
                name: "Projects",
                link: "/admin/projects",
                key: "/admin/projects",
                icon: "Save",
            },
        ],
    },
]

function useStyles() {
    const theme = useTheme()
    const [navStyles, setNavStyles] = useState({})
    const [navStylesSmall, setNavStylesSmall] = useState({})

    useEffect(() => {
        setNavStyles({
            root: {
                background: theme.palette.neutralLight,
                color: theme.palette.black,
                overflowY: "auto",
                width: 250,
                height: "100%",
            },
            link: {
                paddingLeft: 10,
            },
        })

        setNavStylesSmall({
            root: {
                background: theme.palette.neutralLight,
                color: theme.palette.black,
                overflowY: "auto",
                width: 40,
                height: "100%",
            },
            link: {
                paddingLeft: 10,
            },
        })
    }, [theme.palette.neutralLight, theme.palette.black])

    return [navStylesSmall, navStyles]
}

export default function Nav(props) {
    const location = useLocation()
    const history = useHistory()
    const [navStylesSmall, navStyles] = useStyles()
    const infos = useEntityFromConfig(infoConfig)
    const info = infos.filter([])

    return (
        <div className="no-print">
            <FNav
                selectedKey={location.pathname}
                groups={navLinks}
                styles={props.size !== "large" ? navStylesSmall : navStyles}
                onLinkClick={(event, element) => {
                    history.push(element.link)
                }}
            />
        </div>
    )
}
