import React from "react"

import { user as userValadator } from "@sevenpoint/schema"
import config from "../config"
import { EntityList, EntityForm } from "./Entities"

export const userConfig = {
    baseUrl: config.api.baseUrl + "/users",
    type: "USERS",
    stateKey: "users",
    name: "Users",
    nameSingle: "User",
    readScope: "read:users",
    writeScope: "write:users",
    defaultSortKey: "contact.email",
    listColumns: [
        {
            key: "contact.email",
            title: "Email",
            additionalKeys: ["enabled"],
            formatter: (value, values) => {
                let v = value
                if (!values[0]) {
                    v = v + " (disabled)"
                }
                return v
            },
        },
        {
            key: "contact.name",
            title: "Name",
        },
        {
            key: "contact.phone",
            title: "Phone Number",
        },
    ],
    formFields: [
        {
            label: "Name",
            key: "contact.name",
            type: "text",
        },
        {
            label: "Email",
            key: "contact.email",
            type: "email",
        },
        {
            label: "Login",
            key: "enabled",
            type: "dropdown",
            options: [
                { key: 1, text: "Enabled" },
                { key: 0, text: "Disabled" },
            ],
        },
        {
            label: "Password",
            key: "password",
            type: "password",
            nullable: true,
        },
        {
            label: "Address",
            key: "contact.address",
            type: "text",
            option: "multiline",
        },
        {
            label: "Country",
            key: "contact.country",
            type: "country",
        },
        {
            label: "City",
            key: "contact.city",
            type: "text",
        },
        {
            label: "State",
            key: "contact.state",
            type: "state",
            coupledValue: "contact.country",
        },

        {
            label: "Zip/Postal",
            key: "contact.postal",
            type: "text",
        },
        {
            label: "Phone",
            key: "contact.phone",
            type: "tel",
            coupledValue: "contact.country",
        },
    ],
    emptyFormValue: { contact: {} },
    validator: userValadator,
    Form: UserForm,
}

export function UsersList(props) {
    return <EntityList config={userConfig} />
}

export function UserForm(props) {
    return <EntityForm {...props} config={userConfig} />
}
