import { image as imagesValidator } from "@sevenpoint/schema"
import config from "../config"

export function useImageConfig() {
    return {
        baseUrl: config.api.baseUrl + "/images",
        type: "IMAGES",
        stateKey: "images",
        name: "Images",
        nameSingle: "Image",
        readScope: "read:specifications",
        writeScope: "write:specifications",
        defaultSortKey: "name",
        validator: imagesValidator,
        allowDelete: true,
        partitionField: "id",
    }
}

export function usePublicImageConfig(studioId, id) {
    return {
        baseUrl:
            config.api.baseUrl +
            "/public/images?projectId=" +
            id +
            "&studioId=" +
            studioId,
        type: "IMAGES",
        stateKey: "images",
        name: "Images",
        nameSingle: "Image",
        readScope: "read:specifications",
        writeScope: "write:specifications",
        defaultSortKey: "name",
        validator: imagesValidator,
        allowDelete: true,
        insecure: true,
        partitionField: "id",
    }
}
