import enviroment from "./env.js"

const config = {
    main: {
        baseUrl: "https://???",
    },
    api: {
        baseUrl: enviroment.BASE_URL || "http://localhost:1234",
    },
    auth0: {
        domain: enviroment.AUTH0_DOMAIN || "visual-elements.us.auth0.com",
        clientId:
            enviroment.AUTH0_CLIENT_ID || "vHmysBe6lHd34rjud3VX3UT4Tw1ZmO60",
        redirectUri: enviroment.AUTH0_URI || "http://localhost:3000/admin",
        audience:
            enviroment.AUTH0_AUDIENCE || "https://api.sevenpointinteriors.com/",
        scopes: ["read:users write:users read:items write:items"],
    },
    images: {
        baseUrl:
            enviroment.IMAGES_BASEURL ||
            "https://visual-elements.nyc3.digitaloceanspaces.com/",
    },
    dataDog: {
        enabled: enviroment.DD_ON || 0,
        applicationId:
            enviroment.DD_APPLICATION_ID ||
            "4fad9c16-ab25-4b6f-b323-96242b46ced2",
        clientToken:
            enviroment.DD_CLIENT_TOKEN || "pub68b65fdb246dc92cbb87c4b5ce103ef0",
        service: enviroment.DD_CLIENT_TOKEN || "visual-elements-configurator",
        sessionReplayRecording: false,
    },

    nonThemeColors: {
        red: "#FAE6F5",
        yellow: "#F5D79B",
        green: "#C3B4E1",
        black: "#3C3C3C",
    },
    reporting: {
        url: enviroment.REPORTING_URL || "http://localhost:1235",
    },
    showContols: false,
}

export default config
