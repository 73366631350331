import React, { useCallback, useEffect, useState } from "react"
import { useDropzone } from "react-dropzone"
import { v4 as uuidv4 } from "uuid"
import config from "../config"
import { useCalls } from "@sevenpoint/ffsds/src/fe/entity"
import { useImageConfig } from "../components/Images"
import {
    Label,
    useTheme,
    Image,
    ImageFit,
    Stack,
    IconButton,
    Icon,
    mergeStyles,
    TextField,
} from "@fluentui/react"
import Modal from "../layouts/Modal"
import { useGlobalState } from "../state"
import { useEntityFromConfig } from "../hooks/Entity"
import {
    Stage,
    PresentationControls,
    useGLTF,
    PerspectiveCamera,
} from "@react-three/drei"
import * as THREE from "three"

import { Canvas } from "@react-three/fiber"
import { useMaterialMap } from "../components/ItemRender"
import { cloneDeep } from "lodash"

const iconClass = mergeStyles({
    fontSize: 50,
    height: 50,
    width: 50,
    margin: "5 5px",
    cursor: "pointer",
})

const iconSmallClass = mergeStyles({
    fontSize: 20,
    height: 20,
    width: 20,
    margin: "5 5px",
    cursor: "pointer",
})

function ObjStageItems(props) {
    const nodes = Object.values(props.nodes)
    const n = []
    for (const r of nodes) {
        if (r.type === "Mesh") {
            n.push(
                <mesh
                    castShadow
                    receiveShadow
                    geometry={r.geometry}
                    material={
                        r.material.name === "WOOD"
                            ? props.woodMaterial
                            : r.material.name === "METAL"
                            ? props.metalMaterial
                            : r.material.name === "LIGHT"
                            ? new THREE.MeshPhongMaterial({
                                  color: "white",
                              })
                            : r.material.name === "glass"
                            ? r.material
                            : props.material
                    }
                />
            )
        }
    }

    return <>{n}</>
}

export function ObjStage2(props) {
    const { nodes, materials } = useGLTF(props.gltfPath, true)

    const mat = useMaterialMap(
        props.woodMaterial ? props.woodMaterial : props.material
    )
    const woodMaterial = new THREE.MeshPhongMaterial({
        color: null,
        map: mat,
        transparent: true,
    })
    const metalMaterial = new THREE.MeshPhongMaterial({
        color: props.metalMaterial ? props.metalMaterial.color : "black",
        map: null,
        transparent: true,
    })

    return (
        <Stage
            shadows={false}
            adjustCamera
            intensity={1}
            environment={null}
            preset="rembrandt"
        >
            {props.zoom && <PerspectiveCamera makeDefault zoom={props.zoom} />}

            <ObjStageItems
                nodes={nodes}
                material={metalMaterial}
                woodMaterial={woodMaterial}
                metalMaterial={metalMaterial}
            />
        </Stage>
    )
}

export function ObjStage(props) {
    const theme = useTheme()
    return (
        <div
            style={{
                height: props.height ? props.height : "300px",
                width: props.width ? props.width : "inherent",
            }}
        >
            <Canvas
                shadows
                colorManagement
                sRGB={true}
                dpi={window.devicePixelRatio}
                style={{ backgroundColor: theme.palette.themePrimary }}
            >
                <React.Suspense fallback={null}>
                    <ObjStage2 {...props} />
                </React.Suspense>
            </Canvas>
        </div>
    )
}

export function Images(props) {
    const imagesRepo = useEntityFromConfig(useImageConfig())
    const value = props.value
    const images = []
    const [modal, setModal] = useState([])
    const [edit, setEdit] = useState(false)
    const [editName, setEditName] = useState("")
    const state = useGlobalState()

    if (!value) {
        return null
    }
    for (const i of value) {
        const nameQ = props.anyFiles
            ? imagesRepo.filter([{ type: "eq", field: "id", value: i }])
            : []
        const name = nameQ[0] ? nameQ[0].name : ""
        const iconClass = mergeStyles({
            fontSize: 30,
            height: 30,
            width: 30,
            margin: "0 0px",
            cursor: "pointer",
        })
        images.push(
            <>
                {props.gltfModel && (
                    <>
                        <div style={{ width: "100%", textAlign: "right" }}>
                            <Icon
                                className={iconClass}
                                iconName="MiniExpandMirrored"
                                onClick={() => {
                                    setModal(
                                        <Modal
                                            onClose={() => {
                                                setModal(false)
                                            }}
                                        >
                                            <ObjStage
                                                height="600px"
                                                width="600px"
                                                gltfPath={
                                                    config.images.baseUrl + i
                                                }
                                            />
                                        </Modal>
                                    )
                                }}
                            />
                        </div>

                        <ObjStage gltfPath={config.images.baseUrl + i} />
                    </>
                )}
                <Stack horizontal key={i}>
                    {!props.anyFiles && (
                        <Image
                            key={i}
                            imageFit={ImageFit.centerContain}
                            width={props.size ? props.size : 100}
                            height={props.size ? props.size : 100}
                            src={config.images.baseUrl + i}
                            onClick={(e) => {
                                e.stopPropagation()
                                setModal(
                                    <Modal
                                        onClose={() => {
                                            setModal(false)
                                        }}
                                    >
                                        <Image
                                            key={i}
                                            width={"49vw"}
                                            height={"70vh"}
                                            imageFit={ImageFit.contain}
                                            src={config.images.baseUrl + i}
                                        />
                                    </Modal>
                                )
                                return false
                            }}
                        />
                    )}
                    {props.anyFiles && (
                        <>
                            <Icon
                                className={iconSmallClass}
                                iconName="DownloadDocument"
                                onClick={() => {
                                    window.open(config.images.baseUrl + i)
                                }}
                                size="small"
                            />
                            {edit !== i && (
                                <span
                                    onClick={() => {
                                        if (edit !== i && !props.readOnly) {
                                            setEdit(i)
                                            setEditName(name)
                                        }
                                    }}
                                >
                                    {name}
                                </span>
                            )}

                            {edit === i && (
                                <TextField
                                    value={editName}
                                    onChange={(e, value) => {
                                        setEditName(value)
                                    }}
                                    onBlur={() => {
                                        setEdit(false)
                                    }}
                                    onKeyDown={(ev) => {
                                        if (ev.key === "Enter") {
                                            const imgNew = imagesRepo.filter([
                                                {
                                                    type: "one",
                                                    field: "id",
                                                    value: i,
                                                },
                                            ])[0]
                                            imgNew.name = editName
                                            imagesRepo.dispatch.upsert(imgNew)
                                            setEdit(false)
                                        }
                                    }}
                                />
                            )}
                        </>
                    )}
                    {props.allowDelete && !props.readOnly && (
                        <Stack.Item align="center">
                            <IconButton
                                iconProps={{ iconName: "Delete" }}
                                title="Delete"
                                ariaLabel="Delete"
                                onClick={() => {
                                    props.onDelete(i)
                                }}
                            />
                        </Stack.Item>
                    )}
                    {modal}
                </Stack>
            </>
        )
    }
    return <>{images}</>
}

//This is NOT how I invisioned controls working.
// THIS IS FAR to coupled with the backend.
// Hope it's a one off
export default function ImageControl(props) {
    const imagesRepo = useEntityFromConfig(useImageConfig())
    const [showInput, setShowInput] = useState(true)

    useEffect(() => {
        if (props.onlyOne && props.value && props.value.length >= 1) {
            setShowInput(false)
        } else {
            setShowInput(true)
        }
    }, [props.onlyOne, props.value])

    //Low level FFSDS
    const calls = useCalls(config, useImageConfig())
    const [uploadCount, setUploadCount] = useState(0)
    const theme = useTheme()
    const value = props.value
    const onChange = props.onChange
    const onDrop = useCallback(
        async (acceptedFiles) => {
            acceptedFiles.forEach(async (file) => {
                //Really hacky that this is done here! It's terribe
                //This should be moved into the entity somehow
                const form = new FormData()
                const uuid = uuidv4()
                form.set("id", uuid)
                form.set("location", uuid)
                form.set("name", file.name)
                form.set("image", file, uuid)
                setUploadCount(uploadCount + 1)
                await calls.insert(form)
                setUploadCount(uploadCount - 1)
                if (props.onlyOne) {
                    onChange(uuid)
                } else {
                    onChange(value ? value.concat([uuid]) : [uuid])
                }
                imagesRepo.dispatch.refresh([
                    { field: "id", type: "eq", value: uuid },
                ])
            })
        },
        [value, calls, uploadCount, onChange]
    )
    const { getRootProps, getInputProps, isDragActive, isDragReject } =
        useDropzone({
            onDrop,
            accept: props.anyFiles ? null : "image/png, image/gif, image/jpeg",
        })

    return (
        <>
            <Label>{props.label}</Label>
            <Images
                readOnly={props.readOnly}
                anyFiles={props.anyFiles}
                value={
                    props.onlyOne
                        ? props.value
                            ? [props.value]
                            : []
                        : props.value
                }
                allowDelete={props.allowDelete}
                gltfModel={props.gltfModel}
                onDelete={(item) => {
                    if (props.onlyOne) {
                        props.onChange(null)
                    } else {
                        const i = props.value.indexOf(item)
                        if (i > -1) {
                            props.value.splice(i, 1)
                        }
                        props.onChange(props.value)
                    }
                }}
            />
            {!props.readOnly && showInput && (
                <div
                    style={{
                        backgroundColor: theme.palette.white,
                        color: theme.palette.black,
                        padding: theme.spacing.s1,
                        height: "75px",
                        textAlign: "center",
                        border: "1px solid",
                    }}
                    {...getRootProps()}
                >
                    <input {...getInputProps()} />
                    {uploadCount > 0 && <p>Uploading ({uploadCount})</p>}

                    {isDragReject && <p>File type not accepted, sorry!</p>}
                    {!isDragReject &&
                        (isDragActive ? (
                            <p>Drop the files here ...</p>
                        ) : (
                            <p>
                                Drag 'n' drop some files here, or click to
                                select files
                            </p>
                        ))}
                </div>
            )}
        </>
    )
}
