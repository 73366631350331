import * as Papa from "papaparse"
import { useState } from "react"
import { v4 as uuidv4 } from "uuid"

export default function FileImporter(props) {
    const [stage, setStage] = useState(null)
    const changeHandler = (event) => {
        setStage("Uploading file")
        event.preventDefault()
        const reader = new FileReader()
        reader.onload = async (e) => {
            const text = e.target.result
            setStage("Parsing results")

            const results = Papa.parse(text, { header: true })
            let count = 1
            for (const r of results.data) {
                console.log(r)
                const rows = props.entity.filter([
                    {
                        field: props.importKey,
                        type: "eq",
                        value: r[props.importKey],
                    },
                ])
                //We assume loaded
                const obj = {}

                for (const property in props.importMaping) {
                    obj[props.importMaping[property]] = r[property]
                }

                if (rows.length === 0) {
                    obj.id = uuidv4()
                }
                setStage(
                    "Uploading record " + count + " of " + results.data.length
                )
                count++
                console.log(obj)
                if (!(await props.entity.dispatch.upsert(obj))) {
                    setStage(
                        "Error importing record. Importation halted Data:" +
                            JSON.stringify(r)
                    )
                    break
                }
            }
        }
        reader.readAsText(event.target.files[0])
    }
    return (
        <div>
            {stage === null && (
                <input
                    type="file"
                    name="file"
                    accept=".csv"
                    onChange={changeHandler}
                    style={{ display: "block", margin: "10px auto" }}
                />
            )}
            {stage}
        </div>
    )
}
