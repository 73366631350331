import React, { useState, useRef, useMemo, useEffect } from "react"
import { valueInKeyPath } from "@sevenpoint/ffsds/src/shared/keypath"
import { DialogType } from "@fluentui/react/lib/Dialog"
import ConfirmDialog from "./ConfimDialog"

import { CommandBar } from "@fluentui/react/lib/CommandBar"
import TitleContent from "./TitleContent"
import { ShimmeredDetailsList } from "@fluentui/react/lib/ShimmeredDetailsList"
import UpsertPanel from "./UpsertPanel"
import PrintList from "./PrintList"
import { useReactToPrint } from "react-to-print"
import { useId } from "@fluentui/react-hooks"

import {
    DetailsListLayoutMode,
    ConstrainMode,
    SelectionMode,
    Selection,
    ColumnActionsMode,
} from "@fluentui/react/lib/DetailsList"
import {
    ContextualMenu,
    Stack,
    Text,
    TextField,
    ThemeProvider,
    useTheme,
    DetailsRow,
    TooltipHost,
    TooltipDelay,
    DetailsRowCheck,
    Icon,
    mergeStyles,
    mergeStyleSets,
} from "@fluentui/react"
import themes from "../themes"
import Modal from "./Modal"
import FileImporter from "../components/FileImporter"

export function toFluentColumns(
    columns,
    onSort = null,
    genOnColumnClick = null,
    rows = null,
    srows = null
) {
    const c = []
    for (const i in columns) {
        const o = columns[i]
        if (o.key === "__markerColor") {
            continue
        }
        if (o.key === "__lineThrough") {
            continue
        }
        if (o.key === "__mouseOver") {
            continue
        }

        const col = {
            key: i,
            name: o.title,
            printName: o.printTitle ? o.printTitle : o.title,
            fieldName: o.key,
            minWidth: o.minWidth ? o.minWidth : 50,
            maxWidth: o.maxWidth ? o.maxWidth : 200,
            isResizable: true,
            isCollapsible: true,
            onRenderHeader: (column) => {
                column.column.minWidth = 20
                return o.title
            },
        }
        if (o.filterValues && genOnColumnClick && rows) {
            col.columnActionsMode = ColumnActionsMode.hasDropdown
            col.isFiltered = o.isFiltered
            col.onColumnClick = genOnColumnClick(o.filterValues(rows, srows))
        }
        if (onSort) {
            col.onColumnClick = (ev, column) => {
                onSort(o.key)
                column.isSorted = true
                column.isSortedDescending = !column.isSortedDescending
            }
        }
        c.push(col)
    }
    return c
}

export function toFluentItems(rows, columns) {
    const c = []

    for (const i in rows) {
        const o = rows[i]
        const ni = {
            key: i,
            name: "Item " + i,
        }
        ni["_id"] = o["id"]
        for (const column of columns) {
            if (column.key === "__markerColor") {
                ni[Symbol.for("markerColor")] = column.formatter(o)
                continue
            }
            if (column.key === "__lineThrough") {
                ni[Symbol.for("lineThrough")] = column.formatter(o)
                continue
            }
            if (column.key === "__mouseOver") {
                ni[Symbol.for("mouseOver")] = column.formatter(o)
                continue
            }
            let additionalKeys = []
            if (column.formatter) {
                if (column.additionalKeys) {
                    additionalKeys = column.additionalKeys.map((v) =>
                        valueInKeyPath(v, o)
                    )
                }
                if (column.includeEvents) {
                    additionalKeys.push(o[Symbol.for("events")])
                }
                ni[column.key] = column.formatter(
                    valueInKeyPath(column.key, o),
                    additionalKeys
                )
                if (column.printFormatter) {
                    ni[Symbol.for(column.key + "_print")] =
                        column.printFormatter(
                            valueInKeyPath(column.key, o),
                            additionalKeys
                        )
                }
            } else {
                ni[column.key] = valueInKeyPath(column.key, o)
            }
        }
        c.push(ni)
    }
    return c
}

const detailsListStyles = {
    root: { width: "100%", height: "100%" },
}

export default function List(props) {
    const [addModal, setAddModal] = useState(null)
    const [activeItemId, setActiveItemId] = useState(null)
    const [contextualMenuProps, setContextualMenuProps] = useState(null)
    const [print, setPrint] = useState(false)
    const printCover = useRef(true)

    const printComponentRef = useRef()
    const handlePrint = useReactToPrint({
        content: () => printComponentRef.current,
        onBeforeGetContent: () => {
            document.body.style.cursor = "progress"
            setPrint(true)

            //Wait until everything loads
            return new Promise((r) => setTimeout(r, 1000)).then(() => {})
        },
        onAfterPrint: () => {
            setPrint(false)
            document.body.style.cursor = "default"
            printCover.current = true
        },
    })

    const AddForm = props.Form

    const selection = useMemo(() => {
        return new Selection({
            onSelectionChanged: () => {
                if (props.onSelectionChanged) {
                    props.onSelectionChanged(selection.getSelection())
                }
                if (selection.getSelection()[0]) {
                    const sel = selection.getSelection()
                    if (sel.length > 1) {
                        setActiveItemId(null)
                    } else {
                        setActiveItemId(sel[0]["_id"])
                    }
                } else {
                    setActiveItemId(null)
                }
            },
        })
    }, [props])

    const newItem = {
        key: "newItem",
        text: "New",
        iconProps: { iconName: "Add" },
        split: true,
        ariaLabel: "New",
        splitButtonAriaLabel: "More new options",
        disabled: !props.allowAdd,
        onClick: () => {
            if (props.onNew) {
                props.onNew()
            } else {
                setAddModal(
                    <UpsertPanel
                        buttonLabel={props.addButtonLabel}
                        submitRef={submitRef}
                        AddForm={AddForm}
                        onClose={() => {
                            setAddModal(null)
                        }}
                        hideButtons={props.readOnly}
                    />
                )
            }
        },
    }

    if (props.subMenus && props.subMenus.newItem) {
        newItem.subMenuProps = {}

        newItem.subMenuProps.items = [
            { ...newItem, split: false, subMenuProps: null },
            ...props.subMenus.newItem.subMenuProps.items,
        ]
    }
    const submitRef = useRef()

    function printButton(subMenu = false) {
        const pval = {
            key: "print",
            text: "Print",
            split: true,
            iconProps: { iconName: "Print" },
            onClick: () => {
                handlePrint()
            },
        }
        const subPval = { ...pval }
        if (props.PrintCover) {
            pval.subMenuProps = {
                items: [
                    {
                        key: "printWithoutCover",
                        text: "Print Without Cover",
                        iconProps: { iconName: "PageRemove" },
                        onClick: () => {
                            printCover.current = false
                            handlePrint()
                        },
                    },
                    subPval,
                ],
            }
        }
        return pval
    }
    const buttons = useMemo(() => {
        const values = []
        if (!props.hideButtons) {
            values.push(
                ...[
                    newItem,
                    {
                        key: "editItem",
                        text: "Edit",
                        iconProps: { iconName: "Edit" },
                        disabled: activeItemId === null ? true : false,
                        onClick: () => {
                            const i = props.fetchForUpdate(activeItemId)
                            if (props.onEdit) {
                                props.onEdit(i)
                            } else {
                                setAddModal(
                                    <UpsertPanel
                                        buttonLabel={props.updateButtonLabel}
                                        submitRef={submitRef}
                                        AddForm={AddForm}
                                        onClose={() => {
                                            setAddModal(null)
                                        }}
                                        values={i}
                                        lightDismiss={props.readOnly}
                                        //A bit of a hack for now
                                        hideButtons={
                                            props.readOnly || i.archived
                                        }
                                    />
                                )
                            }
                        },
                    },
                ]
            )
            if (!props.disablePrint) {
                values.push(printButton())
            }
            if (props.onExport) {
                const exportButton = {
                    key: "export",
                    text: "Export",
                    iconProps: { iconName: "Table" },
                    onClick: async () => {
                        props.onExport(
                            props.rows,
                            toFluentItems(props.rows, props.columns)
                        )
                    },
                }
                if (props.subMenus && props.subMenus.export) {
                    exportButton.subMenuProps = {}
                    exportButton.subMenuProps.items = [
                        { ...exportButton, split: false, subMenuProps: null },
                        ...props.subMenus.export.subMenuProps.items,
                    ]
                }
                values.push(exportButton)
            }
            if (props.onImport) {
                const importButton = {
                    key: "import",
                    text: "Import",
                    iconProps: { iconName: "Import" },
                    onClick: async () => {
                        props.onImport()
                    },
                }
                values.push(importButton)
            }

            if (props.onDelete) {
                values.push({
                    key: "deleteItem",
                    text: "Delete",
                    iconProps: { iconName: "Delete" },
                    disabled: activeItemId === null ? true : false,
                    onClick: () => {
                        const options = {
                            type: DialogType.normal,
                            title: "Delete?",
                            closeButtonAriaLabel: "Do Nothing",
                            subText:
                                "Are you sure you want to delete that record? This action is irreversible.",
                        }
                        setAddModal(
                            <ConfirmDialog
                                dialogContentProps={options}
                                onConfirm={async () => {
                                    const i = props.fetchForUpdate(activeItemId)
                                    await props.onDelete(i)
                                    setAddModal(null)
                                    setActiveItemId(null)
                                    selection.setItems([], true)
                                }}
                                onDismiss={() => {
                                    setAddModal(null)
                                }}
                                actionText="Delete"
                                dismissText="Do Nothing"
                            />
                        )
                    },
                })
            }
            if (props.onRefresh) {
                values.push({
                    key: "refresh",
                    text: "Refresh",
                    iconProps: { iconName: "Refresh" },
                    onClick: async () => {
                        if (await props.onRefresh()) {
                            const event = new CustomEvent("infoMessage", {
                                detail: {
                                    type: "info",
                                    message: `Data has been refreshed`,
                                },
                            })
                            document.dispatchEvent(event)
                        } else {
                            const event = new CustomEvent("infoMessage", {
                                detail: {
                                    type: "error",
                                    message: `Could not refresh.`,
                                },
                            })
                            document.dispatchEvent(event)
                        }
                    },
                })
            }
        }
        if (props.doNotHidePrint && props.hideButtons) {
            values.push(printButton())
        }

        if (props.additionalButtons) {
            for (const b of props.additionalButtons) {
                if (b != null) {
                    if (props.hideButtons) {
                        if (b.doNotHide) {
                            values.push(b)
                        }
                    } else {
                        values.push(b)
                    }
                }
            }
        }
        return values
    }, [props, activeItemId, AddForm, handlePrint, selection])
    const columns = toFluentColumns(
        props.columns,
        props.onSort,
        genOnColumnClick,
        props.fullRows ? props.fullRows : props.rows,
        props.rows
    )
    const items = toFluentItems(props.rows, props.columns)

    const onRenderItemColumn = (item, index, column) => {
        let fieldContent = item[column.fieldName]
        return (
            <div
                data-selection-disabled={true}
                onClick={(e) => {
                    e.stopPropagation()
                    const i = props.fetchForUpdate(item["_id"])
                    if (props.onRowClick) {
                        props.onRowClick(i)
                    }
                    if (!AddForm) {
                        return
                    }
                    if (props.onEdit) {
                        props.onEdit(i)
                    } else {
                        setAddModal(
                            <UpsertPanel
                                buttonLabel={props.updateButtonLabel}
                                submitRef={submitRef}
                                AddForm={AddForm}
                                onClose={() => {
                                    setAddModal(null)
                                }}
                                values={i}
                                hideButtons={props.readOnly || i.archived}
                                lightDismiss={props.readOnly || i.archived}
                            />
                        )
                    }
                }}
            >
                {fieldContent}
            </div>
        )
    }

    const onHideContextualMenu = React.useCallback(
        () => setContextualMenuProps(null),
        []
    )

    function getContextualMenuProps(ev, column, i) {
        return {
            items: i,
            target: ev.currentTarget,
            gapSpace: 10,
            isBeakVisible: true,
            onDismiss: onHideContextualMenu,
        }
    }

    function genOnColumnClick(i) {
        return function _onColumnClick(ev, column) {
            if (column.columnActionsMode !== ColumnActionsMode.disabled) {
                setContextualMenuProps(getContextualMenuProps(ev, column, i))
            }
        }
    }

    function _onRenderRow(props) {
        const markColor = props.item[Symbol.for("markerColor")]
        const lineThrough = props.item[Symbol.for("lineThrough")]
        const mouseOver = props.item[Symbol.for("mouseOver")]

        return (
            <>
                <DetailsRow
                    {...props}
                    styles={{
                        root: {
                            textDecoration: lineThrough
                                ? "line-through"
                                : "none",
                        },
                        checkCell: {
                            boxShadow: markColor
                                ? "inset 8px 0px 0px 0px " + markColor
                                : "0",
                        },
                    }}
                    onRenderCheck={(props) => {
                        return (
                            <TooltipHost
                                delay={TooltipDelay.long}
                                content={mouseOver}
                            >
                                <Stack>
                                    <DetailsRowCheck {...props} />
                                </Stack>
                            </TooltipHost>
                        )
                    }}
                />
            </>
        )
    }

    const classNames = mergeStyleSets({
        listContainer: {
            height: "calc(100vh - 170px) !important",
            overflow: "auto !important",
            width: "100%",
            ...(props.styles && props.styles.listContainer
                ? props.styles.listContainer
                : {}),
        },
    })

    return (
        <>
            <TitleContent title={props.title}>
                {addModal}
                <div className="only-print">
                    <Text variant={"xLarge"}>{props.printTitle}</Text>
                </div>
                <div className="no-print">
                    <Stack horizontal={!props.searchStack}>
                        {buttons.length >= 1 && (
                            <Stack.Item
                                styles={{
                                    root: {
                                        minWidth: 0,
                                    },
                                }}
                                grow={3}
                            >
                                <CommandBar items={buttons} />
                            </Stack.Item>
                        )}
                        {props.onSearch && (
                            <Stack.Item
                                grow={1}
                                styles={{
                                    root: {
                                        minWidth: 0,
                                        paddingLeft: props.searchStack ? 30 : 0,
                                    },
                                }}
                            >
                                <TextField
                                    styles={{
                                        root: {
                                            maxWidth: 150,
                                        },
                                    }}
                                    onChange={(e, v) => {
                                        props.onSearch(v)
                                    }}
                                    placeholder="Search"
                                ></TextField>
                                <div>&nbsp;</div>
                            </Stack.Item>
                        )}
                    </Stack>
                </div>
                <div
                    className={classNames.listContainer}
                    data-is-scrollable="true"
                >
                    <ShimmeredDetailsList
                        columns={columns}
                        setKey="none"
                        selectionMode={
                            props.multiSelection
                                ? SelectionMode.multiple
                                : SelectionMode.single
                        }
                        layoutMode={DetailsListLayoutMode.justified}
                        selectionPreservedOnEmptyClick={true}
                        items={items}
                        constrainMode={ConstrainMode.unconstrained}
                        styles={detailsListStyles}
                        enableShimmer={props.isLoading}
                        ariaLabelForShimmer={"Loading"}
                        onRenderItemColumn={
                            props.onRenderItemColumn
                                ? props.onRenderItemColumn
                                : onRenderItemColumn
                        }
                        selection={selection}
                        onRenderRow={_onRenderRow}
                        groups={props.groups}
                    />
                    {contextualMenuProps && (
                        <ContextualMenu {...contextualMenuProps} />
                    )}
                </div>
            </TitleContent>

            {print && (
                <ThemeProvider
                    theme={{
                        palette: {
                            ...themes.light,
                        },
                        fonts: {
                            small: { fontSize: "10px" },
                            xSmall: { fontSize: "10px" },
                        },
                        defaultFontStyle: { fontFamily: "Poppins-Regular" },
                    }}
                    style={{ height: "100%", width: "100%" }}
                    applyTo="element"
                >
                    <div
                        ref={printComponentRef}
                        id="print-area"
                        style={{
                            color: "black",
                            backgroundColor: "white",
                            padding: 0,
                            margin: 0,
                        }}
                    >
                        <PageNumbers
                            header={
                                <Text variant={"xSmall"}>
                                    {props.printTitle
                                        ? props.printTitle
                                        : props.title}
                                </Text>
                            }
                            footer={
                                <Text variant={"xSmall"}>
                                    {props.printFooter}
                                </Text>
                            }
                        >
                            {props.PrintCover && printCover.current && (
                                <props.PrintCover />
                            )}
                        </PageNumbers>
                        <PrintList items={items} columns={columns} />
                    </div>
                </ThemeProvider>
            )}
        </>
    )
}

function PageNumbers(props) {
    return (
        <>
            <div className="only-print">
                <div style={{ position: "fixed", top: 0 }}>{props.header}</div>
            </div>

            <div>{props.children}</div>
            <div style={{ border: "0" }} className="only-print">
                <div style={{ position: "fixed", bottom: 0 }}>
                    {props.footer}
                </div>
            </div>
        </>
    )
}
