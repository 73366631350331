import React from "react"

import { useState } from "react"
import { useId } from "@fluentui/react-hooks"

import {
    Callout,
    ColorPicker,
    Label,
    Stack,
    TextField,
    useTheme,
} from "@fluentui/react"

export default function Color(props) {
    const theme = useTheme()
    const [showPicker, setShowPicker] = useState(false)
    const labelId = useId("callout-label")
    const buttonId = useId("callout-button")

    return (
        <Stack>
            <Label>{props.label}</Label>
            <div
                id={buttonId}
                style={{
                    height: theme.spacing.l2,
                    width: theme.spacing.l2,
                    paddingLeft: theme.spacing.l2,
                    paddingRight: theme.spacing.l2,

                    backgroundColor: props.value,
                    border: "1px dashed black",
                }}
                onClick={() => {
                    setShowPicker(true)
                }}
            >
                {""}
            </div>
            {showPicker && (
                <Callout
                    setInitialFocus
                    ariaLabelledBy={labelId}
                    target={`#${buttonId}`}
                    onDismiss={() => {
                        setShowPicker(false)
                    }}
                >
                    <ColorPicker
                        onChange={(ev, value) => {
                            props.onChange(ev, value.str)
                        }}
                        alphaType={"none"}
                        id={labelId}
                    ></ColorPicker>
                </Callout>
            )}
        </Stack>
    )
}
